import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import crosswordSlice from "./Slices/crosswordSlice";
import userSlice from "./Slices/userSlice";
import crosswordApi from "./APIs/crosswordApi";
import popoutsSlice from "./Slices/popoutsSlice";
import rewardSlice from "./Slices/rewardSlice";
import connectionHelperSlice from "./Slices/connectionHelperSlice";
import routerSlice from "./Slices/routerSlice";
import adaptiveSlice from "./Slices/adaptiveSlice";

const store = configureStore({
  reducer : {
    [crosswordApi.reducerPath] : crosswordApi.reducer,
    "adaptive" : adaptiveSlice.reducer,
    "crossword" : crosswordSlice.reducer,
    "user" : userSlice.reducer,
    "popouts" : popoutsSlice.reducer,
    "router" : routerSlice.reducer,
    "reward" : rewardSlice.reducer,
    "connectionHelper" : connectionHelperSlice.reducer
  },
  middleware : (getDefaultMiddleware) => {
    return getDefaultMiddleware({})
      .concat(crosswordApi.middleware)
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store;