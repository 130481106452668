import React, {CSSProperties} from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {selectCurrentDiamonds, selectCurrentLocalLevel} from "../../../store/Slices/userSlice";
import Diamonds from "../../../assets/svg/diamond.svg"
import PNGs from "../../../assets/images";
import {Button} from "../../UI";
import "./StartScreenPopout.css"
import {setCurrentPopout} from "../../../store/Slices/popoutsSlice";
import {setRoute} from "../../../store/Slices/routerSlice";

export interface IStartScreenPopoutProps {

}

function StartScreenPopout({}: IStartScreenPopoutProps) {
  const currentLevel = useAppSelector(selectCurrentLocalLevel)
  const currentDiamonds = useAppSelector(selectCurrentDiamonds)
  const dispatch = useAppDispatch()

  const onClick = () => {
    dispatch(setCurrentPopout({
      name: "hidden"
    }))
    dispatch(setRoute({
      view: "default",
      panel: "game"
    }))
  }
  return (
    <div className={`h-full relative`}>
      <div className={`top-[10%] w-full absolute`}>
        <div className={`relative`}>
          <div className={`absolute w-1/4 max-w-[100px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`}>
            <div className={`relative mt-32`}>
              <img src={PNGs.Light} alt="" className={`w-[80vw] absolute -translate-y-1/3 -translate-x-1/3 -left-2 max-w-[400px] mx-auto block`}/>
              <img src={PNGs.Light} alt="" className={`w-[80vw] absolute -translate-y-1/3 -translate-x-1/3 -left-2 max-w-[400px] mx-auto block`}/>
              <img src={Diamonds} className={`w-full block relative`} alt=""/>
            </div>
            <div
              className={`absolute -bottom-12 text-stroke-chocolate text-white font-extrabold stroke-chocolate stroke-2 left-1/2 -translate-x-1/2 text-center text-4xl`}>
              {currentDiamonds}
            </div>
          </div>
        </div>
        <div>

        </div>
      </div>
      <div className={`absolute bottom-[15%] w-full max-w-[648px] text-center`}>
        <Button className={`mx-auto px-6 py-2 text-[24px]`} onClick={onClick} type={`primary`}>
          Уровень {currentLevel}
        </Button>
      </div>
    </div>
  );
}

export default StartScreenPopout;