import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ConnectedLetters} from "../../entities/ConnectedLetters";
import {LetterConnectCircle, LetterConnectController} from "../../entities/LetterConnectCircle";
import {IConnectedLetters} from "../../entities/LetterConnectCircle/LetterConnectController";
import {Tip} from "../../entities";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {selectCurrentConnectedLetters} from "../../../store/Slices/crosswordSlice";
import {selectCurrentLocalLevel} from "../../../store/Slices/userSlice";
import {setIsBlurred} from "../../../store/Slices/connectionHelperSlice";
import {setPlaygroundHeight} from "../../../store/Slices/adaptiveSlice";

export interface IPlaygroundProps {
  connectionLetters: string[]
  letterConnectController: LetterConnectController | null
  canvasSize?: number
}

function Playground({
                      connectionLetters,
                      canvasSize,
                      letterConnectController,
                      ...props
                    }: IPlaygroundProps & React.HTMLProps<HTMLDivElement>) {
  const dispatch = useAppDispatch()

  const connectedLetters = useAppSelector(selectCurrentConnectedLetters)
  const currentLevel = useAppSelector(selectCurrentLocalLevel)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!currentLevel) return;
    if (currentLevel <= 3 || currentLevel === 5) {
      dispatch(setIsBlurred(true))
    } else {
      dispatch(setIsBlurred(false))
    }
  }, [currentLevel]);

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeFn: ResizeObserverCallback = (entries, observer) => {
      const contentRect = entries[0].contentRect
      const playgroundHeight = contentRect.height;
      dispatch(setPlaygroundHeight(playgroundHeight))
    }

    const resizeObserver = new ResizeObserver(resizeFn);
    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className={`relative ${props.className}`}
    >
      {(Number(currentLevel) >= 5) && <Tip className={`right-[12px] top-2 absolute`} type={`lamp`}/>}
      {(Number(currentLevel) >= 3) && <Tip className={`left-[12px] top-2 absolute`} type={`hammer`}/>}

      <ConnectedLetters connectedLetters={connectedLetters}/>
      {(letterConnectController && typeof canvasSize !== "undefined") &&
          <LetterConnectCircle connectedLetters={connectedLetters} canvasSize={canvasSize}
                               letterConnector={letterConnectController}/>
      }
    </div>
  );
}

export default Playground;