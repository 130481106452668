import React, {CSSProperties, ForwardedRef, forwardRef, MutableRefObject, useEffect, useRef, useState} from 'react';

export type TDrawFunction = (c : CanvasRenderingContext2D, canvas : HTMLCanvasElement) => void;
export interface ICanvasComponentCustomProps {
  width : number
  height : number
  draw : TDrawFunction
  prepareAnimation ?: (...args : any) => (() => void) | void | undefined;
  isDraggable ?: boolean
  outsideRef ?: MutableRefObject<HTMLCanvasElement | null>
}


export type ICanvasComponentProps = ICanvasComponentCustomProps &
  React.DetailedHTMLProps<React.HTMLProps<HTMLCanvasElement>, HTMLCanvasElement>

const CanvasComponent = ({width, height, outsideRef, prepareAnimation, draw, isDraggable, ...props} : ICanvasComponentProps) => {
  const ref = useRef<HTMLCanvasElement>(null)
  const [style, setStyle] = useState<CSSProperties>({width : undefined, height : undefined});
  useEffect(() => {
    if (outsideRef && ref) {
      outsideRef.current = ref.current
    }
  }, [ref?.current, outsideRef?.current]);

  useEffect(() => {
    if (!ref || !ref.current || !width || !height) return;
    const canvas = ref.current

    const context = canvas.getContext("2d");
    if (!context) return
    draw(context, canvas)
    if (!prepareAnimation) return;
    const animation = prepareAnimation(context, canvas)
    if (!animation) return;
    requestAnimationFrame(animation)
    return () => {
      cancelAnimationFrame(animation as unknown as number)
    }
  }, [draw, prepareAnimation]);

  useEffect(() => {
    if (!ref.current) return;
    const canvas = ref.current;
    const ratio = Math.ceil(window.devicePixelRatio);
    canvas.width = width * ratio;
    canvas.height = height * ratio;
    const newWidth = `${width}px`;
    const newHeight = `${height}px`;
    setStyle({width : newWidth, height : newHeight})
  }, [width, height]);

  return (
    <canvas style={style} className={`select-none cursor-pointer ${props.className}`} ref={ref} width={width} height={height} {...props}></canvas>
  );
}

export default CanvasComponent;