import Diamond from "./diamond.svg"
import LampOn from "./lamp-turn-on.svg"
import LampOff from "./lamp-turn-off.svg"
import Hammer from "./hammer.svg"

export const PreloadSVGs = {
  Diamond,
  LampOn,
  LampOff,
  Substrate : "https://crossword.cookieapp.ru/storage/assets/svg/substrate.svg",
  Hammer,
}

export const PostLoadedSVGs = {
  WrappedGift : "https://crossword.cookieapp.ru/storage/assets/svg/wrapped-gift.svg",
}

const SVGs = {
  ...PreloadSVGs,
  ...PostLoadedSVGs
}

export default SVGs