import {IFirstLetterMeta} from "../models";
import {isVerticalFirstLetter} from "./isVerticalFirstLetter";
import {isHorizontalFirstLetter} from "./isHorizontalFirstLetter";

export function getFirstLettersFromCrossword(originCrossword : string[][]) {
  const firstLetters: IFirstLetterMeta[] = []
  originCrossword.forEach((letterRow, y) => {
    letterRow.forEach((letter, x) => {
      if (isHorizontalFirstLetter(originCrossword, x, y)) {
        firstLetters.push({
          letter,
          direction: "horizontal",
          x,
          y
        })
      }
      if (isVerticalFirstLetter(originCrossword, x, y)) {
        firstLetters.push({
          letter,
          direction: "vertical",
          x,
          y
        })
      }
    })
  })
  return firstLetters;
}

