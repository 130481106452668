import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IGameData, TTipType} from "../../models";
import {GetLaunchParamsResponse, UserInfo} from "@vkontakte/vk-bridge";
import {RootState} from "../store";
import crosswordApi from "../APIs/crosswordApi";
import {TUserData} from "../../hooks/useInitExternalPlatform";

interface IUserState {
  launchParams : undefined | GetLaunchParamsResponse
  userData: TUserData | undefined
  gameData: IGameData | undefined
  isInitialized: boolean
  token: undefined | string
  notifications_allowed : boolean
}

const initialState: IUserState = {
  launchParams : undefined,
  gameData: undefined,
  userData: undefined,
  isInitialized: false,
  token: undefined,
  notifications_allowed : false
}

export type ClaimRewardPayload = {
  diamonds: number,
  hammer: number
  lamp: number
}

const crosswordSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<TUserData>) => ({
      ...state,
      userData: action.payload
    }),
    setLaunchParams : (state, action : PayloadAction<GetLaunchParamsResponse>) => {
      return {
        ...state,
        launchParams : action.payload
      }
    },
    updateLocalLevel: (state, action: PayloadAction<number>) => {
      if (state.gameData) {
        state.gameData.localLevel = action.payload
      }
    },
    updateServerLevel: (state, action: PayloadAction<number>) => {
      if (state.gameData) {
        state.gameData.serverLevel = action.payload
      }
    },
    setIsInitialized: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isInitialized: action.payload
      }
    },
    setTipCount: (state, action: PayloadAction<{ type: TTipType, count: number }>) => {
      if (!state.gameData) return;
      state.gameData.tips[action.payload.type] = action.payload.count
    },
    setDiamondsCount: (state, action: PayloadAction<number>) => {
      if (!state.gameData) return;
      return {
        ...state,
        gameData: {
          ...state.gameData,
          diamonds: action.payload,
        }
      }
    },
    setNotificationAllowed : (state, action : PayloadAction<boolean>) => {
      return {
        ...state,
        notifications_allowed : action.payload
      }
    },
    increaseDiamondsCount: (state, action: PayloadAction<number>) => {
      if (!state.gameData) return;
      return {
        ...state,
        gameData: {
          ...state.gameData,
          diamonds: state.gameData.diamonds + action.payload,
        }
      }
    },
    claimRewards: (state, action: PayloadAction<ClaimRewardPayload>) => {
      if (!state.gameData) return;
      return {
        ...state,
        gameData: {
          ...state.gameData,
          diamonds: state.gameData.diamonds + action.payload.diamonds,
          tips: {
            hammer: state.gameData.tips.hammer + action.payload.hammer,
            lamp: state.gameData.tips.lamp + action.payload.lamp
          }
        }
      }
    },
  },
  extraReducers: builder => {
    builder.addCase("RESET_STORE", (state, action) => {
      return {
        ...state,
        isInitialized : false,
        gameData : undefined
      }
    })
    builder.addMatcher(crosswordApi.endpoints.init.matchFulfilled, (state, action) => {
      return {
        ...state,
        gameData: {
          localLevel: action.payload.user.level,
          diamonds: action.payload.user.diamonds,
          serverLevel: action.payload.user.level,
          tips: {
            hammer: action.payload.user.hammer_help,
            lamp: action.payload.user.lamp_help
          },
          config: action.payload.config_main
        },
        // @ts-ignore
        token: action.meta.baseQueryMeta.response.headers.get("X-Token")
      }
    })
  }
})

export const selectCurrentUserData = (state: RootState) => state.user.userData
export const selectNotificationsAllowed = (state : RootState) => state.user.notifications_allowed
export const selectCurrentServerData = (state: RootState) => state.user.gameData
export const selectCurrentLocalLevel = (state: RootState) => state.user.gameData?.localLevel
export const selectCurrentServerLevel = (state: RootState) => state.user.gameData?.serverLevel
export const selectCurrentLaunchParams = (state: RootState) => state.user.launchParams
export const selectCurrentDiamonds = (state: RootState) => state.user.gameData?.diamonds
export const selectCurrentTips = (state: RootState) => state.user.gameData?.tips
export const selectCurrentConfig = (state: RootState) => state.user.gameData?.config
export const selectCurrentUserDataStatus = (state: RootState) => !!state.user.userData
export const selectCurrentIsInitialized = (state: RootState) => state.user.isInitialized

export const {
  setLaunchParams,
  setUser,
  setIsInitialized,
  updateLocalLevel,
  setTipCount,
  setDiamondsCount,
  claimRewards,
  updateServerLevel,
  increaseDiamondsCount,
  setNotificationAllowed
} = crosswordSlice.actions

export default crosswordSlice;
