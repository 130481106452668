import React, {MouseEventHandler, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {selectCurrentWarningPopout, setCurrentWarningPopout} from "../../../store/Slices/popoutsSlice";
import CloseIcon from "../../UI/CloseIcon";
import {selectCurrentBannerHeight} from "../../../store/Slices/adaptiveSlice";
import useOutsideClick from "../../../hooks/useClickOutside";

function UtilityWarningPopout() {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const currentWarningPopout = useAppSelector(selectCurrentWarningPopout)
  const bannerHeight = useAppSelector(selectCurrentBannerHeight)
  const containerRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const onOkayClick: MouseEventHandler<HTMLDivElement> = (e) => {
    setIsDisplayed(false)
    setTimeout(() => {
      dispatch(setCurrentWarningPopout(undefined))
    }, 150)
  }
  let content = null;

  useOutsideClick(containerRef, onOkayClick)
  useEffect(() => {
    if (currentWarningPopout) {
      setIsDisplayed(true)
    }
  }, [currentWarningPopout]);

  if (currentWarningPopout) {
    content = (
      <div className={`h-screen fixed bg-[#100500]/30 z-[100] w-full box-border max-w-[648px]`}>
        <div
          ref={containerRef}
          style={{
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), inset -3px -5px 4px #BC7026, inset 3px -5px 4px #BC7026, inset 0px 3px 2px #FFC995, inset 0px -5px 4px #BC7026",
            bottom : `${bannerHeight}px`
        }}
          className={`w-[100%] max-w-[648px] translate-y-full ${isDisplayed ? "popup" : "popout"} bg-[#E9A460] fixed rounded-t-[48px] box-border pt-6 px-4 pb-5`}
        >
          <div
            onClick={onOkayClick}
            className={`absolute p-2 -right-2 -top-2 flex items-center justify-center z-10`}>
            <div
              style={{
                boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -1px 3px 1px #BC7026 inset, 0px 2px 2px 0px #FFC995 inset"
              }}
              className={`relative w-10 h-10 bg-[#E9A460] rounded-full`}
            >
              <CloseIcon className={`absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2`} size={17} color={`#814C17`}/>
            </div>
          </div>
          <div>
            <div
              style={{boxShadow: "0px -1px 4px #D28030, 0px 2px 4px #D28030"}}
              className={`p-3 font-black bg-[#FEE6BD] text-black text-center text-[24px] rounded-[33px]`}
            >
              {currentWarningPopout.heading}
            </div>
            <div
              style={{boxShadow: "0px -1px 4px #D28030, 0px 2px 4px #D28030"}}
              className={`bg-[#FEE6BD] font-medium text-[20px] text-black mt-6 items-center py-6 rounded-[33px] grid grid-cols-1 gap-y-3 px-4`}
            >
              {currentWarningPopout.article}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return content;
}

export default UtilityWarningPopout;