import {useEffect, useState} from "react";
import {PreloadSVGs} from "../assets/svg"
import {PreloadImages} from "../assets/images";

export interface TUseInitAppAssetsProps {
  onComplete: () => any
}

type TUseInitAppAssets = ({onComplete}: TUseInitAppAssetsProps) => { success: boolean };

const loadFont = async () => {
  const font = new FontFace("appFont", `url("https://crossword.cookieapp.ru/storage/assets/fonts/MPLUSRounded1c-Bold.ttf")`)
  await font.load().then((loadedFont) => {
    document.fonts.add(loadedFont)
  })
}

export const useInitAppAssets: TUseInitAppAssets = function ({onComplete}) {
  const [success, setSuccess] = useState<boolean>(false);

  const [preloadedAssetsCount, setPreloadedAssetsCount] = useState(0);

  const PreloadedAssets = [...Object.values(PreloadSVGs), ...Object.values(PreloadImages)]

  const preloadAsset = async (src : string) => {
    const image = new Image();
    image.src = src
    image.onload = () => {
      setPreloadedAssetsCount(prevState => prevState + 1)
    }
  }

  const postLoadAssets = () => {

  }

  useEffect(() => {
    const preloadAssets = async () => {
      await loadFont()
      await Promise.all(PreloadedAssets.map(preloadAsset))
    }
    preloadAssets().then(() => {
      postLoadAssets()
    })
  }, []);

  useEffect(() => {
    const assetsIsPreloaded = PreloadedAssets.length === preloadedAssetsCount
    if (assetsIsPreloaded) {
      onComplete();
      setSuccess(() => true)
    }
  }, [preloadedAssetsCount]);

  return {success}
}