import RatingCupSubstrate from "../images/RatingCupSubstrate.png"
import UnwrappedGift from "../images/UnwrappedGift.png"
import RatingPopoutSubstrate from "../images/RatingPopoutSubstrate.png"
import RingIcon from "./Ring.png"
import ScrollBody from "./ScrollBody.png"
import ScrollTop from "./ScrollTop.png"
import RingSubstrate from "./RingSubstrate.png"
import LevelButtonCurrent0 from "./LevelButtonCurrent0.png"
import LevelButtonCurrent1 from "./LevelButtonCurrent1.png"
import LevelButtonCurrent2 from "./LevelButtonCurrent2.png"
import LevelButtonCurrent3 from "./LevelButtonCurrent3.png"
import LevelButtonCurrent4 from "./LevelButtonCurrent4.png"
import LevelsBackground from "./LevelsBackground.png"
import NextLocationIcon from "./NextLocationIcon.png"
import DialogueSubstrate from "./DialogueSubstrate.png"

function canUseWebP() {
  var elem = document.createElement('canvas');
  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
}

const isWebpSupported = canUseWebP();

const background = document.querySelector("#background");

if (background) {
  // @ts-ignore
  background.style.background = `url(${process.env.REACT_APP_SERVER_URL}/storage/assets/images/bg-substrate.${isWebpSupported ? "webp" : "png"})`
}

export const Backgrounds = {
// @ts-ignore
  Default : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/background.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  FirstBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/1.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  SecondBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/2.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  ThirdBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/3.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  FourthBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/4.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  FifthBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/5.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  SixthBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/6.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  SeventhBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/7.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  EighthBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/8.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  NinthBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/9.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  TenthBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/10.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  EleventhBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/11.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  TwelfthBackground : `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/backgrounds/12.${isWebpSupported ? "webp" : "png"}`
}

export const PreloadImages = {
// @ts-ignore
  Light: `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/light.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  TipLight: `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/tip-back-light.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  Finger: `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/Finger.${isWebpSupported ? "webp" : "png"}`,
// @ts-ignore
  ChampionCup: `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/ChampionCup.${isWebpSupported ? "webp" : "png"}`,
  LevelButtonCurrent0,
  LevelButtonCurrent1,
  LevelButtonCurrent2,
  LevelButtonCurrent3,
  LevelButtonCurrent4,
  DialogueSubstrate,
  LevelsBackground,
  NextLocationIcon,
  RingIcon,
  ScrollBody,
  ScrollTop,
  RatingCupSubstrate,
  RingSubstrate,
// @ts-ignore
  Lock: `${process.env.REACT_APP_SERVER_URL}/storage/assets/images/Lock.${isWebpSupported ? "webp" : "png"}`
}

export const PostLoadedImages = {
  ...Backgrounds,
  UnwrappedGift,
  RatingPopoutSubstrate,
}

const PNGs = {
  ...PreloadImages,
  ...PostLoadedImages,
  ...Backgrounds
}


export default PNGs