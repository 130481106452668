import React, {SVGProps} from 'react';

export interface ICloseIconProps {
  size : number
  color ?: string
}

function CloseIcon({size, color, ...props} : SVGProps<SVGElement> & ICloseIconProps) {
  return (
    <svg width={size} height={size} className={`${props.className}`} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M1.49159 1.49156C2.36204 0.621118 3.73971 0.587515 4.56871 1.41651L7.57077 4.41858L10.7229 1.26641C11.5934 0.395962 12.9711 0.36236 13.8001 1.19136C14.629 2.02035 14.5954 3.39803 13.725 4.26847L10.5728 7.42064L13.5749 10.4227C14.4039 11.2517 14.3703 12.6294 13.4998 13.4998C12.6294 14.3703 11.2517 14.4039 10.4227 13.5749L7.42067 10.5728L4.2685 13.725C3.39805 14.5954 2.02038 14.629 1.19138 13.8C0.362386 12.971 0.395988 11.5934 1.26643 10.7229L4.4186 7.57074L1.41654 4.56868C0.587541 3.73968 0.621143 2.36201 1.49159 1.49156Z" fill={color}/>
    </svg>

  );
}

export default CloseIcon;