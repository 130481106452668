import {TTipType} from "../../../models";
import Hammer from "../../../assets/svg/hammer.svg";
import LampOn from "../../../assets/svg/lamp-turn-off.svg";
import {activateHammer, openLetter} from "../../../store/Slices/crosswordSlice";

export const iconsMap: Record<TTipType, string> = {
  "hammer": Hammer,
  "lamp": LampOn
}
export const sizeMap: Record<TTipType, string> = {
  "hammer": "w-14",
  "lamp": "w-12",
}
export const actionMap: Record<TTipType, () => any> = {
  "hammer": activateHammer,
  "lamp": openLetter
}