import React from 'react';

export interface ITipPopoutProps {

}

function TipPopout({} : ITipPopoutProps) {
  return (
    <div></div>
  );
}

export default TipPopout;