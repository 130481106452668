import React, {HTMLProps, ReactNode, useEffect, useRef, useState} from 'react';
import {useAppSelector} from "../../store/store";
import {selectCurrentAvailableHeight} from "../../store/Slices/adaptiveSlice";
import {selectPreparedCrossword} from "../../store/Slices/crosswordSlice";

export interface IGrid {
  rows: number
  cols: number
  gap: number
  setGridElementSize: React.Dispatch<React.SetStateAction<number>>
  children: ReactNode
}

export const SquaredElementsGrid = ({
                                      rows,
                                      cols,
                                      gap,
                                      setGridElementSize,
                                      children,
                                      ...rest
                                    }: IGrid & HTMLProps<HTMLDivElement>) => {
  const [widthScaledGridSize, setWidthScaledGridSize] = useState<number | undefined>(undefined);
  const [heightScaledGridSize, setHeightScaledGridSize] = useState<number | undefined>(undefined);

  const availableHeight = useAppSelector(selectCurrentAvailableHeight)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!containerRef.current) return;

    const calculateGridSize: ResizeObserverCallback = (entries, observer) => {
      if (!containerRef.current) return;
      const width = entries[0].contentRect.width
      const height = entries[0].contentRect.height
      if (height > innerHeight) return;
      const newWidthScaledGridSize = (width - (cols - 1) * gap) / cols
      const newHeightScaledGridSize = (height - (rows - 1) * gap) / rows
      setWidthScaledGridSize(newWidthScaledGridSize)
      setHeightScaledGridSize(newHeightScaledGridSize)
    }

    const resizeObserver = new ResizeObserver(calculateGridSize)

    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }

  }, [rows, cols]);

  useEffect(() => {
    const cellSize = // Подбираем оптимальный размер ячейки
      Math.min(Number(widthScaledGridSize), Number(heightScaledGridSize));
    setGridElementSize(cellSize)
  }, [widthScaledGridSize, heightScaledGridSize]);

  const cellSize = // Подбираем оптимальный размер ячейки
    Math.min(Number(widthScaledGridSize), Number(heightScaledGridSize));


  return (
    <div {...rest}
         className={`w-full flex justify-center items-center ${!cellSize ? "opacity-0" : "opacity-100"} transition-opacity h-full box-border ${rest.className}`}
         ref={containerRef}
    >
      {
        (!Number.isNaN(cellSize) && cellSize) && (
          <div
            className="grid"
            style={{
              gridTemplateColumns: `repeat(${cols}, ${cellSize}px)`,
              gridAutoRows: `${cellSize}px`,
              gap: `${gap}px`,
              maxWidth: `${cols * cellSize + (cols - 1) * gap}px`,
              maxHeight: `${rows * cellSize + (rows - 1) * gap}px`,
            }}
          >
            {children}
          </div>
        )
      }
    </div>
  );
};

export default SquaredElementsGrid;