import React, {CSSProperties} from 'react';
import {PanelHeader, PlatformType, usePlatform} from "@vkontakte/vkui";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
  increaseDiamondsCount,
  selectCurrentDiamonds,
  selectNotificationsAllowed,
  setNotificationAllowed
} from "../../../store/Slices/userSlice";
import DiamondsSrc from "../../../assets/svg/diamond.svg";
import VKWebApp from "../../../utility/VKWebApp";
import {PlusIcon} from "../../UI/";
import {PreloadImages} from "../../../assets/images";
import {motion} from 'framer-motion';
import {setCurrentPopout} from "../../../store/Slices/popoutsSlice";
import * as amplitude from "@amplitude/analytics-browser";
import "./CrosswordPanelHeader.css"
import VKConnect from "../../../utility/VKConnect";

export interface ICrosswordPanelHeaderProps {
  transparent: boolean
}

function CrosswordPanelHeader({transparent = false}: ICrosswordPanelHeaderProps) {
  const points = useAppSelector(selectCurrentDiamonds)
  const dispatch = useAppDispatch()
  const platform = usePlatform()

  const onBalanceClick = () => {
    const identifyEvent = new amplitude.Identify()
    const paymentCount = 5

    identifyEvent.prepend("payment_history", paymentCount)
    identifyEvent.add("pay_num", 1)

    const query = {
      "payment_count": paymentCount
    }

    VKWebApp.useShowOrderBox({
      type: "item",
      item: "1_default"
    }).then(data => {
      if (data.status === "success") {
        dispatch(increaseDiamondsCount(200))
        amplitude.identify(identifyEvent)
        amplitude.track("HardPay", query)
      }
    }).catch(error => {

    })
  }

  const onRatingClick = () => {
    dispatch(setCurrentPopout({
      name: "rating"
    }))
  }

  const onRingClick = () => {
    VKConnect.send("VKWebAppAllowNotifications")
      .then(data => {
        if (data.result) {
          dispatch(setNotificationAllowed(true))
        }
      }).catch(error => {
      console.log(error)
    })
  }

  return (
    <PanelHeader separator={false} style={
      {
        "--vkui--color_background_content": "transparent",
        "--vkui--color_field_border_alpha": 0
      } as CSSProperties} shadow={false} transparent={transparent} before={
      <div className={`flex items-center space-x-4`}>
        <Balance points={points} onClick={onBalanceClick} platform={platform}/>
        <Rating onClick={onRatingClick}/>
        <Ring onClick={onRingClick}/>
      </div>
    }>

    </PanelHeader>
  );
}

const Balance = ({
                   points,
                   onClick,
                   platform
                 }: { points: number | undefined, platform: PlatformType, onClick: (...args: any) => any }) => (
  <div
    className={`min-w-[24px] h-[27px] bg-milk text-[#C0554F] rounded-xl relative font-extrabold ml-5 text-[18px] px-7 flex justify-start items-center`}>
    {points}
    <div className={`w-10 h-10 absolute -left-4 rounded-full`}>
      <img src={DiamondsSrc} alt="" className={`w-full`}/>
    </div>
    {platform !== "ios" ?
      (
        <div
          className={`absolute right-1 w-[22px] h-[22px] flex justify-center items-center rounded-full bg-[#14C7F6]`}>
          <PlusIcon onClick={onClick}/>
        </div>
      )
      : null
    }
  </div>
)

const Rating = ({onClick}: { onClick: (...args: any) => any }) => {
  return (
    <motion.div
      whileTap={{
        scale: 1.1
      }}
      onClick={onClick}
      className={`relative flex items-end mt-2`}
    >
      <img src={PreloadImages.ChampionCup} alt=""
           className={`absolute -top-1.5 left-1/2 -translate-x-1/2 z-[0] w-8 z-[1]`}/>
      <img src={PreloadImages.RatingCupSubstrate} alt="" className={`relative z-[0]`}/>
    </motion.div>
  )
}

const Ring = ({onClick}: { onClick: (...args: any) => any }) => {
  const notificationsAllowed = useAppSelector(selectNotificationsAllowed)
  // @ts-ignore
  const platform = process.env.REACT_APP_PLATFORM
  return (notificationsAllowed || platform === "playdeck") ? null : (
    <motion.div
      onClick={onClick}
      whileTap={{
        scale: 1.1
      }}
      className={`w-fit relative`}
    >
      <img src={PreloadImages.RingSubstrate} className={`w-[42px] mt-1`} alt=""/>
      <img src={PreloadImages.RingIcon}
           className={`w-[30px] top-1 ring-animation left-1 absolute`}
      />
    </motion.div>
  );

}

export default CrosswordPanelHeader;