import {TPlatform} from "../ExternalPlatformController";
import {BaseQueryFn} from "@reduxjs/toolkit/query";
import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {__DEV, __DEV_ACC} from "../../VKConnect";
import {RootState} from "../../../store/store";

// @ts-ignore
const baseUrl = process.env.REACT_APP_SERVER_URL;
const baseUrlApi = `${baseUrl}/api`;

const aa = "?vk_access_token_settings=&vk_app_id=51665576&vk_are_notifications_enabled=0&vk_is_app_user=1&vk_is_favorite=0&vk_language=ru&vk_platform=mobile_web&vk_ref=other&vk_ts=1702896754&vk_user_id=100228180&sign=fIwEcMlkSVrZsDMyKbWfgfUsuINBO4xIuWYzXdWRVkE"
const tyler_gosling = "vk_access_token_settings=&vk_app_id=51665576&vk_are_notifications_enabled=0&vk_is_app_user=1&vk_is_favorite=0&vk_language=ru&vk_platform=mobile_web&vk_ref=other&vk_ts=1722767751&vk_user_id=868684734&sign=eNRt3DJ_RasP_f93MK28txnRGCTAlutW4Altc2tUVvM"

export const baseQuery: Record<TPlatform, BaseQueryFn> = {
  vk : fetchBaseQuery({
    baseUrl : baseUrlApi,
    headers: {
      "X-Sign": __DEV ?
        __DEV_ACC === "aa" ? aa : tyler_gosling
        : location.search,
    },
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).user.token
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `${token}`)
      }

      return headers
    }
  }),
  playdeck : fetchBaseQuery({
    baseUrl : baseUrlApi,
    prepareHeaders: (headers, {getState}) => {
      const token = (getState() as RootState).user.token
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `${token}`)
      }

      return headers
    }
  })
}