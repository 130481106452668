import React, {ReactNode, useEffect} from 'react';
import {
  AdvertisingPopout,
  LevelChangerPopout,
  StartScreenPopout,
  TipPopout,
  RatingPopout,
  ErrorPopout
} from "../../popouts";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {selectCurrentPopoutName, setGameIsHidden} from "../../../store/Slices/popoutsSlice";
import {selectCurrentIsInitialized} from "../../../store/Slices/userSlice";
import {selectCurrentAvailableHeight, selectCurrentBannerHeight} from "../../../store/Slices/adaptiveSlice";
import {TPopouts} from "../../../models";

function UtilityPopoutWrapper({}) {
  const currentPopoutName = useAppSelector(selectCurrentPopoutName)
  const isInitialized = useAppSelector(selectCurrentIsInitialized)
  const dispatch = useAppDispatch()
  const bannerHeight = useAppSelector(selectCurrentBannerHeight) ?? 0

  useEffect(() => {
    if (currentPopoutName === "start-screen" || !isInitialized) {
      dispatch(setGameIsHidden(true))
    } else {
      dispatch(setGameIsHidden(false))
    }
  }, [currentPopoutName]);


  const availablePopouts: Record<TPopouts, ReactNode> = {
    "start-screen": <StartScreenPopout/>,
    "level-changer": <LevelChangerPopout/>,
    "tip": <TipPopout/>,
    "advertising": <AdvertisingPopout/>,
    "rating": <RatingPopout/>,
    "error": <ErrorPopout/>,
    "hidden": null
  }

  const selectedPopout = availablePopouts[currentPopoutName]


  return selectedPopout ?
    (<div style={{
      height: `calc(100vh - ${bannerHeight}px)`
    }} className={`fixed w-screen max-w-[648px] mx-auto z-[20]`}>
      {selectedPopout}
    </div>)
    : null
}

export default UtilityPopoutWrapper;