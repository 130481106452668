import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ILettersData} from "../../components/entities/LetterConnectCircle/LetterConnectController";
import {RootState} from "../store";
import crosswordApi from "../APIs/crosswordApi";
import crosswordSlice from "./userSlice";

export interface IConnectionHelperState {
  currentLettersData: ILettersData[] | undefined,
  connectionCanvasElementData: {
    size: number,
    x: number,
    y: number,
    width: number,
    height: number
  } | undefined
  isBlurred: boolean,
  isTraining: boolean
  tipTrainingProgress: undefined | TTipTrainingProgress
}

type TTipTrainingProgress = {
  hammer: {
    isUsed: boolean
  },
  lamp: {
    isUsed: boolean
  },
}

const initialState: IConnectionHelperState = {
  currentLettersData: undefined,
  connectionCanvasElementData: undefined,
  isBlurred: true,
  isTraining: true,
  tipTrainingProgress: undefined
}

type TCanvasElementData = Exclude<IConnectionHelperState["connectionCanvasElementData"], undefined>;

const connectionHelperSlice = createSlice({
  name: "connectionHelper",
  initialState,
  reducers: {
    setCurrentLettersData: (state, action: PayloadAction<ILettersData[]>) => {
      return {
        ...state,
        currentLettersData: action.payload
      }
    },
    setCanvasElementData: (state, action: PayloadAction<TCanvasElementData>) => {
      return {
        ...state,
        connectionCanvasElementData: action.payload
      }
    },
    setIsBlurred: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isBlurred: action.payload
      }
    },
    completeTipTrainingProgress : (state, action : PayloadAction<{type : "hammer" | "lamp"}>) => {
      if (!state.tipTrainingProgress) return;
      state.tipTrainingProgress[action.payload.type].isUsed = true
    },
    setIsUsed : (state, action : PayloadAction<{type : "hammer" | "lamp"}>) => {
      if (!state.tipTrainingProgress) return;
      state.tipTrainingProgress[action.payload.type].isUsed = true

    },
  },
  extraReducers: builder => {
    builder.addCase("RESET_STORE", (state, action) => {
      return {
        ...initialState
      }
    }),
    builder.addCase(crosswordSlice.actions.updateLocalLevel, (state, action) => {
      return {
        ...state,
        tipTrainingProgress:
          (action.payload === 3 || action.payload === 5)
            ? {
              hammer: {
                isUsed: false
              },
              lamp: {
                isUsed: false
              }
            } : undefined,
      }
    })
    builder.addMatcher(crosswordApi.endpoints.init.matchFulfilled, (state, action) => {
      return {
        ...state,
        tipTrainingProgress: {
          hammer: {
            isUsed: action.payload.user.level !== 3
          },
          lamp: {
            isUsed: action.payload.user.level !== 5
          }
        }
      }
    })
  }
})

export const selectCurrentLettersData = (state: RootState) => state.connectionHelper.currentLettersData
export const selectCurrentConnectionCanvasElementData = (state: RootState) => state.connectionHelper.connectionCanvasElementData
export const selectCurrentIsBlurred = (state: RootState) => state.connectionHelper.isBlurred
export const selectCurrentTipTrainingProgress = (state: RootState) => state.connectionHelper.tipTrainingProgress
export const selectCurrentIsTraining = (state: RootState) => state.connectionHelper.isTraining
export const selectCurrentTrainingProgress = (state: RootState) => state.connectionHelper.tipTrainingProgress

export const {
  setCurrentLettersData,
  setCanvasElementData,
  setIsBlurred,
  setIsUsed,
  completeTipTrainingProgress
} = connectionHelperSlice.actions
export default connectionHelperSlice