import React, {useEffect, useRef} from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {selectCurrentPopoutProps, setCurrentPopout, TErrorPopoutProps} from "../../../store/Slices/popoutsSlice";
import {Button} from "../../UI";
import CloseIcon from "../../UI/CloseIcon";
import useOutsideClick from "../../../hooks/useClickOutside";
import {resetStore} from "../../../store/Actions/resetStore";
import * as amplitude from "@amplitude/analytics-browser";
import {selectCurrentServerData, selectCurrentUserData} from "../../../store/Slices/userSlice";

function ErrorPopout({}) {
  const {reset, requestName} = useAppSelector(selectCurrentPopoutProps) as TErrorPopoutProps
  let content;
  const dispatch = useAppDispatch()
  const userData = useAppSelector(selectCurrentUserData)
  const gameData = useAppSelector(selectCurrentServerData)
  const containerRef = useRef<HTMLDivElement>(null)
  if (requestName === "useAdvertising") {
    content = "Потеряна связь с сервером при просмотре рекламы :("
  } else if (requestName === "useTip") {
    content = "Потеряна связь с сервером при использовании подсказки :("
  } else if (requestName === "levelChange") {
    content = "Потеряна связь с сервером при смене уровня :("
  } else {
    content = "Потеряна связь с сервером :("
  }

  useEffect(() => {
    const query = {
      request_name: requestName,
      user_id: userData?.user_id,
      local_level : gameData?.localLevel,
      server_level : gameData?.serverLevel
    }
    amplitude.track("ServerError", query)
  }, []);

  const onOkayClick = () => {
    dispatch(setCurrentPopout({
      name : "hidden"
    }))
    dispatch(resetStore())
  }

  useOutsideClick(containerRef, onOkayClick)


  return (
    <div className={`h-full bg-[#100500]/80 w-full box-border pt-32 max-w-[648px] z-10`}>
      <div
        ref={containerRef}
        style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), inset -3px -5px 4px #BC7026, inset 3px -5px 4px #BC7026, inset 0px 3px 2px #FFC995, inset 0px -5px 4px #BC7026"}}
        className={`w-[80%] bg-[#E9A460] rounded-[48px] box-border pt-2 mx-auto px-3 pb-5 relative`}
      >
        <div
          onClick={onOkayClick}

          className={`absolute p-2 -right-2 -top-2 flex items-center justify-center z-10`}>
          <div
            style={{
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -1px 3px 1px #BC7026 inset, 0px 2px 2px 0px #FFC995 inset"
            }}
            className={`relative w-10 h-10 bg-[#E9A460] rounded-full`}
          >
            <CloseIcon className={`absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2`} size={17} color={`#814C17`}/>
          </div>
        </div>
        <p className={`text-[22px] my-0 mb-4 text-center text-white drop-shadow font-bold`}>Выбери подсказку</p>
        <div
          style={{boxShadow: "0px -1px 4px #D28030, 0px 2px 4px #D28030"}}
          className={`bg-[#FEE6BD] items-center py-6 rounded-[33px] grid grid-cols-1 gap-y-3 px-4`}
        >
          <span className={`text-[24px] text-[#171717] font-bold`}>
            Упс... Что-то пошло не так!
          </span>
          <span className={`text-[20px] text-[#171717]`}>
            {content}
          </span>
          <Button
            onClick={onOkayClick}
            type={"primary"}
            className={`flex items-center text-[24px] justify-center py-2`}
          >
            Переподключиться
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ErrorPopout;