import React from "react";
import Diamond from "../../../../assets/svg/diamond.svg";
import PNGs from "../../../../assets/images";
import Hammer from "../../../../assets/svg/hammer.svg";
import LampOn from "../../../../assets/svg/lamp-turn-on.svg";
import {TReward} from "../LevelChangerPopout";

export interface ILevelChangerRewardWrapperProps {
  reward: TReward
  localLevel: number | undefined
}

export default function LevelChangerRewardWrapper({
                                     reward,
                                     localLevel,
                                     ...props
                                   }: ILevelChangerRewardWrapperProps & React.HTMLProps<HTMLDivElement>) {


  const lockedDiamonds = reward?.diamonds.adv
  const rewardHammer = Object.entries((reward.hammer)).map(([key, value], index) => {
      return (
        <>
          {
            // @ts-ignore
            Boolean(reward.hammer[key]) &&
              <Bonus
                  type={"hammer"}
                  key={`reward_hammer_${index}`}
                // @ts-ignore
                  count={value}
                  isAd={key === "adv"}
              />
          }
        </>)
    }
  )

  const rewardLamp = Object.entries((reward.lamp)).map(([key, value], index) => {
      return (
        <>
          {
            // @ts-ignore
            Boolean(reward.lamp[key]) &&
              <Bonus
                  type={"lamp"}
                  key={`reward_lamp_${index}`}
                // @ts-ignore
                  count={value}
                  isAd={key === "adv"}
              />
          }
        </>)
    }
  );

  let marginTop = ""

  if (innerHeight < 500) {
  marginTop = "mt-2"
  } else if (innerHeight < 600) {
  marginTop = "mt-4"
  } else if (innerHeight < 700) {
  marginTop = "mt-5"
  } else {
  marginTop = "mt-8"
  }

  return (
    <div className={`flex flex-col ${marginTop} justify-center ${props.className}`}
    >
      <div
        className={`flex ${Number(localLevel) % 8 !== 0 ? window.innerHeight < 600 ? "flex-row space-x-8" : "flex-col space-y-4" : "flex-row space-x-8"} items-center justify-center`}>
        {Boolean(reward?.diamonds) &&
            <div className={`flex items-center`}>
                <img src={Diamond} alt="diamond" className={`h-[6vh]`}/>
                <span
                    className={`text-[#F662DF] ml-1 font-extrabold text-2xl`}>
                    +{reward.diamonds.count}
                </span>
            </div>
        }
        {
          Boolean(lockedDiamonds) &&
            <div className={`flex items-center relative`}>
                <div className={`relative`}>
                    <img src={Diamond} alt="diamond" className={`h-[6vh] opacity-60`}/>
                    <img src={PNGs.Lock} alt="lock" className={`absolute right-0 bottom-0`}/>
                </div>
                <span
                    className={`text-[#F662DF] ml-1 font-extrabold text-2xl`}
                >
                    +{lockedDiamonds}
                </span>
            </div>
        }
      </div>
      {
        Number(localLevel) % 8 === 0 && <div className={`flex space-x-8 items-center`}>
          {
            Boolean(reward?.hammer.count || reward?.hammer.adv) && rewardHammer
          }
          {
            Boolean(reward?.lamp.count || reward?.lamp.adv) && rewardLamp
          }
          </div>
      }
    </div>
  )
}

const Bonus = ({type, count, isAd}: {
  type: "hammer" | "lamp",
  count: number,
  isAd: boolean
}) => (
  <div className={`flex items-center`}>
    <div className={`relative h-[8vh] ${Boolean(isAd) && "opacity-60"}`}>
      <img src={type === "hammer" ? Hammer : LampOn} alt="bonus logo" className={`h-full`}/>
      <img src={PNGs.Light} alt=""
           className={`w-[180px] -z-10 ml-1.5 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 absolute`}/>
      {Boolean(isAd) && <img src={PNGs.Lock} alt="" className={`absolute right-0 bottom-0`}/>}
    </div>
    <span className={`text-white ml-1 font-extrabold text-2xl`}>+{count}</span>
  </div>
)