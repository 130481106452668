import mockAPI from '@vkontakte/vk-bridge-mock'
import realAPI from '@vkontakte/vk-bridge'

export const __DEV = false

const aa = 100228180
const tyler_gosling = 868684734
export let __DEV_ACC: "tg" | "aa" = "tg" // tg - tyler gosling
// @ts-ignore
export const __DEV_ID = __DEV_ACC === "aa" ? aa : tyler_gosling

const VKConnect = __DEV ? mockAPI : realAPI;

export default VKConnect