import SVGs from "../../../../assets/svg";
import React from "react";

export interface ILevelChangerHeaderProps {
  localLevel: number | undefined
}

export default function LevelChangerHeader({localLevel}: ILevelChangerHeaderProps) {
  let levelTextMargin;
  let levelTextSize;

  if (innerHeight < 550) {
    levelTextMargin = "mt-0.5"
  } else if (innerHeight < 600) {
    levelTextMargin = "mt-1"
  } else if (innerHeight < 650) {
    levelTextMargin = "mt-2"
  } else {
    levelTextMargin = "mt-4"
  }
  return (
    <div>
      <div className={`w-[280px] text-center h-[12vh] max-h-[80px] relative block`}>
        <img src={SVGs.Substrate} className={`h-[12vh] max-w-full max-h-[101px]`} alt=""/>
        <div
          style={{
            textShadow: "0px 3px 4px rgba(0, 0, 0, 0.35)",
            fontSize: "min(4vh, 32px)"
          }}
          className={`text-center w-full absolute top-[35%] text-white font-extrabold left-1/2 -translate-x-1/2 -translate-y-1/2`}
        >
          Победа!
        </div>
      </div>
      <div
        style={{
          textShadow: "0px 3px 4px rgba(0, 0, 0, 0.35)",
        }}
        className={`text-center text-[18px] w-full ${levelTextMargin} ${levelTextSize} text-white font-extrabold`}
      >
        Уровень {localLevel} пройден!
      </div>
    </div>
  )
}