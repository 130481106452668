import React from 'react';

function SecondStar({size, className}: { size: number, className : string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} className={className} height={30 / 32 * size} viewBox="0 0 32 30" fill="none">
      <g filter="url(#filter0_dii_319_174)">
        <path
          d="M13.3368 2.49058C14.1286 1.30486 15.8714 1.30486 16.6632 2.49058L19.8094 7.20185C20.0741 7.59816 20.4716 7.88694 20.9303 8.01618L26.3832 9.55256C27.7556 9.93922 28.2941 11.5967 27.4111 12.7162L23.9027 17.1643C23.6076 17.5385 23.4557 18.0057 23.4746 18.4819L23.6984 24.1427C23.7548 25.5674 22.3448 26.5918 21.0073 26.098L15.6927 24.1358C15.2457 23.9707 14.7543 23.9707 14.3073 24.1358L8.99272 26.098C7.65517 26.5918 6.24522 25.5674 6.30157 24.1427L6.52544 18.4819C6.54427 18.0057 6.39245 17.5385 6.09732 17.1643L2.58887 12.7162C1.70587 11.5967 2.24442 9.93922 3.61679 9.55256L9.06972 8.01618C9.52842 7.88694 9.92589 7.59816 10.1906 7.20185L13.3368 2.49058Z"
          fill="#82DBEB"/>
        <path
          d="M13.3368 2.49058C14.1286 1.30486 15.8714 1.30486 16.6632 2.49058L19.8094 7.20185C20.0741 7.59816 20.4716 7.88694 20.9303 8.01618L26.3832 9.55256C27.7556 9.93922 28.2941 11.5967 27.4111 12.7162L23.9027 17.1643C23.6076 17.5385 23.4557 18.0057 23.4746 18.4819L23.6984 24.1427C23.7548 25.5674 22.3448 26.5918 21.0073 26.098L15.6927 24.1358C15.2457 23.9707 14.7543 23.9707 14.3073 24.1358L8.99272 26.098C7.65517 26.5918 6.24522 25.5674 6.30157 24.1427L6.52544 18.4819C6.54427 18.0057 6.39245 17.5385 6.09732 17.1643L2.58887 12.7162C1.70587 11.5967 2.24442 9.93922 3.61679 9.55256L9.06972 8.01618C9.52842 7.88694 9.92589 7.59816 10.1906 7.20185L13.3368 2.49058Z"
          stroke="#0D9DB9"/>
      </g>
      <defs>
        <filter id="filter0_dii_319_174" x="0.657715" y="-1.89893" width="30.6846" height="31.623"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dx="1" dy="1"/>
          <feGaussianBlur stdDeviation="1"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_319_174"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_319_174" result="shape"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dy="-3"/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.202083 0 0 0 0 0.690703 0 0 0 0 0.808333 0 0 0 1 0"/>
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_319_174"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                         result="hardAlpha"/>
          <feOffset dy="4"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.68471 0 0 0 0 0.90467 0 0 0 0 0.975833 0 0 0 1 0"/>
          <feBlend mode="normal" in2="effect2_innerShadow_319_174" result="effect3_innerShadow_319_174"/>
        </filter>
      </defs>
    </svg>
  );
}

export default SecondStar;