import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

export interface IAdaptiveState {
  availableHeight : number,
  playgroundHeight : undefined | number,
  bannerHeight : number
}

const initialState: IAdaptiveState = {
  availableHeight : innerHeight,
  playgroundHeight : undefined,
  bannerHeight : 0
}

const adaptiveSlice = createSlice({
  name : "adaptive",
  initialState,
  reducers : {
    setAvailableHeight : (state, action : PayloadAction<number>) =>
      ({...state, availableHeight : action.payload }),
    setPlaygroundHeight : (state, action : PayloadAction<number>) =>
      ({...state, playgroundHeight : action.payload }),
    setBannerHeight : (state, action : PayloadAction<number>) =>
      ({...state, bannerHeight : action.payload})
  }
})

const safeInsetBottom = Number(getComputedStyle(document.documentElement).getPropertyValue("--vkui_internal--safe_area_inset_bottom").replace("px", ""))

export const selectCurrentAvailableHeight = (store : RootState) => store.adaptive.availableHeight
export const selectCurrentPlaygroundHeight = (store : RootState) => store.adaptive.playgroundHeight
export const selectCurrentBannerHeight = (store : RootState) => store.adaptive.bannerHeight ?
  store.adaptive.bannerHeight + safeInsetBottom
  : 0
export const {
  setAvailableHeight,
  setPlaygroundHeight,
  setBannerHeight
} = adaptiveSlice.actions

export default adaptiveSlice;