import React, {useEffect, useRef, useState} from 'react';
import {Panel} from '@vkontakte/vkui';
import {Crossword, CrosswordPanelHeader, Playground} from "../../widgets";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
  openWord,
  selectCrosswordHashmap, selectCurrentConnectionLetters,
  selectLastGuess,
  selectPreparedCrossword,
  updateCrossword
} from "../../../store/Slices/crosswordSlice";
import {selectCurrentGameIsHidden} from "../../../store/Slices/popoutsSlice";
import {
  selectCurrentLocalLevel,
  selectCurrentServerLevel,
} from "../../../store/Slices/userSlice";
import ConnectionHelper from "../../widgets/ConnectionHelper/ConnectionHelper";
import {LetterConnectController} from "../../entities/LetterConnectCircle";
import {selectCurrentIsBlurred} from "../../../store/Slices/connectionHelperSlice";

export interface IGameProps {
  id: string;
}

import {Backgrounds} from "../../../assets/images"
import {selectCurrentAvailableHeight, selectCurrentPlaygroundHeight} from "../../../store/Slices/adaptiveSlice";

type Backgrounds = keyof typeof Backgrounds

const Game = ({id}: IGameProps) => {
  const dispatch = useAppDispatch()
  const words = useAppSelector(selectPreparedCrossword)
  const crosswordHashMap = useAppSelector(selectCrosswordHashmap)
  const lastGuess = useAppSelector(selectLastGuess)
  const gameIsHidden = useAppSelector(selectCurrentGameIsHidden)
  const connectionLetters: string[] = useAppSelector(selectCurrentConnectionLetters)
  const localLevel = useAppSelector(selectCurrentLocalLevel)
  const gameIsBlackedOut = useAppSelector(selectCurrentIsBlurred)
  const currentLocalLevel = useAppSelector(selectCurrentLocalLevel)
  const currentServerLevel = useAppSelector(selectCurrentServerLevel)
  const levelPassedPercentage = Number(currentLocalLevel) < 513 ? 99.84 - 0.15 * (Number(currentLocalLevel) - 1) : 0.01

  const [currentBackgroundStyle, setCurrentBackgroundStyle] = useState({
    backgroundImage: `url(${Backgrounds["Default"]})`,
    backgroundPosition: "50% 70%",
    backgroundSize: 'cover',
  });

  useEffect(() => {
    if (!lastGuess || !crosswordHashMap) return;
    if (crosswordHashMap.hashMap[lastGuess]) {
      dispatch(openWord(crosswordHashMap.hashMap[lastGuess]))
    }
  }, [lastGuess]);

  useEffect(() => {
    if (!currentServerLevel) return;
    let currentBackground: Backgrounds = "Default";

    if (currentServerLevel <= 8) {
      currentBackground = "Default"
    } else if (currentServerLevel <= 8) {
      currentBackground = "FirstBackground"
    } else if (currentServerLevel <= 16) {
      currentBackground = "SecondBackground"
    } else if (currentServerLevel <= 24) {
      currentBackground = "ThirdBackground"
    } else if (currentServerLevel <= 32) {
      currentBackground = "FourthBackground"
    } else if (currentServerLevel <= 40) {
      currentBackground = "FifthBackground"
    } else if (currentServerLevel <= 48) {
      currentBackground = "SixthBackground"
    } else if (currentServerLevel <= 56) {
      currentBackground = "SeventhBackground"
    } else if (currentServerLevel <= 64) {
      currentBackground = "EighthBackground"
    } else if (currentServerLevel <= 72) {
      currentBackground = "NinthBackground"
    } else if (currentServerLevel <= 80) {
      currentBackground = "TenthBackground"
    } else if (currentServerLevel <= 88) {
      currentBackground = "EleventhBackground"
    } else if (currentServerLevel <= 96) {
      currentBackground = "TwelfthBackground"
    }

    setCurrentBackgroundStyle({
      backgroundImage: `url(${Backgrounds[currentBackground]})`,
      backgroundPosition: "50% 70%",
      backgroundSize: 'cover',
    })
  }, [currentServerLevel]);

  const gameHeight = innerHeight;

  const [letterConnectController, setLetterConnectController] = useState<LetterConnectController | null>(null);
  const playgroundHeight = useAppSelector(selectCurrentPlaygroundHeight)
  const canvasSize = playgroundHeight

  useEffect(() => {
    if (!canvasSize) return;
    setLetterConnectController(new LetterConnectController(connectionLetters, canvasSize, dispatch))
  }, [connectionLetters.join(""), canvasSize]);

  const currentHeight = useAppSelector(selectCurrentAvailableHeight) ?? 0
  const gameAreaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!gameAreaRef.current) return
    const preventScroll = (e: Event) => {
      e.preventDefault()
    }

    gameAreaRef.current.addEventListener("touchmove", preventScroll)

    return () => {
      if (gameAreaRef.current) {
        gameAreaRef.current.removeEventListener("touchmove", preventScroll)
      }
    }
  }, [gameAreaRef.current]);

  return (
    <Panel id={id} style={{
      maxHeight: `${currentHeight}px`
    }}>
      <div
        style={{height: `${currentHeight}px`, ...currentBackgroundStyle}}
        className={`w-screen fixed max-w-[648px] mx-auto`}
      />
      <div className={`grow flex max-h-full top-0 w-screen max-w-[648px] mx-auto bg-cover relative`}
      >
        {(!gameIsHidden) &&
            <div ref={gameAreaRef} className={`flex w-full grow flex-col`}>
              {
                gameIsBlackedOut &&
                  <div style={{height: `${currentHeight}px`}}
                       className={`fixed pointer-events-none top-0 bottom-0 mx-auto max-w-[648px] left-0 right-0 bg-black/60 z-[1]`}
                  />
              }
                <ConnectionHelper currentLevel={localLevel}/>
                <CrosswordPanelHeader transparent={true}/>

                <Crossword
                    className={`grow max-h-[50%] ${localLevel === 3 ? "" : "z-[1]"}`}
                    preparedCrossword={words}
                />

                <Playground
                    letterConnectController={letterConnectController}
                    canvasSize={canvasSize}
                    connectionLetters={connectionLetters}
                    className={`z-[1] h-[40%] max-h-[300px] box-border w-full pt-4 pb-10 max-w-[648px] flex justify-center items-center`}
                />
                <div className={`absolute bottom-4 text-center w-full max-w-[648px] mx-auto font-bold text-[1rem]`}>
                    <p className={`my-0`}
                       style={{textShadow: "0px 3px 4px rgba(0, 0, 0, 0.35)", fontFamily: "Rounded Mplus 1c Bold"}}>
                        Уровень {currentLocalLevel} прошли {levelPassedPercentage.toFixed(2)}%
                    </p>
                </div>
            </div>}
      </div>
    </Panel>
  );
};

export default Game;