import React from 'react';

function PlusIcon({...props} : React.SVGProps<any>) {
  return (
      <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g filter="url(#filter0_d_171_1642)">
          <path fillRule="evenodd" clipRule="evenodd" d="M10 2C11.1046 2 12 2.83947 12 3.875V7.625H16C17.1046 7.625 18 8.46447 18 9.5C18 10.5355 17.1046 11.375 16 11.375H12V15.125C12 16.1605 11.1046 17 10 17C8.89543 17 8 16.1605 8 15.125V11.375H4C2.89543 11.375 2 10.5355 2 9.5C2 8.46447 2.89543 7.625 4 7.625H8V3.875C8 2.83947 8.89543 2 10 2Z" fill="#FFFDFA"/>
        </g>
        <defs>
          <filter id="filter0_d_171_1642" x="0" y="0" width="20" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_171_1642"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_171_1642" result="shape"/>
          </filter>
        </defs>
      </svg>

  );
}

export default PlusIcon;