import VKConnect from "./VKConnect";
import {EAdsFormats} from "@vkontakte/vk-bridge";

export default {
  useTaptic : (style : "light" | "medium" | "heavy") => {
    return VKConnect.send('VKWebAppTapticImpactOccurred', {
      style
    })
        .then((data) => {
          if (data.result) {
            // Вибрация вызвана
          }
        })
        .catch((error) => {
          // Ошибка
        });
  },
  useShowOrderBox : ({type, item} : {type : string, item : string}) => {
    return VKConnect.send('VKWebAppShowOrderBox', {type : "item", item})
  },
  useShowNativeAds : (type : "reward" | "interstitial") => {
    return VKConnect.send('VKWebAppShowNativeAds', {
      ad_format: type as EAdsFormats /* Тип рекламы */
    })
  },
  useCheckNativeAds : (type : "reward" | "interstitial") => {
    return VKConnect.send('VKWebAppCheckNativeAds', {
      ad_format : type as EAdsFormats
    })
  }
}