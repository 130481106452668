import {ICoordinates} from "./LetterConnectCircle";
import VKWebApp from "../../../utility/VKWebApp";
import {useAppDispatch} from "../../../store/store";
import {setCurrentLettersData} from "../../../store/Slices/connectionHelperSlice";
import {useDispatch} from "react-redux";
import {setCurrentConnectedLetters} from "../../../store/Slices/crosswordSlice";

export interface ILettersData {
  letter: string,
  centerX: number
  centerY: number
  id: number
  isConnected: boolean
  connectedTo: ILettersData | undefined
}

export interface ICanvasData {
  canvasSize: number
  canvasCenterPoint: number
  canvasOffsetX: number
  canvasOffsetY: number
  canvasDegreesPerSector: number
}

function getDistance(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)
}

export interface IConnectedLetters {
  letter: string,
  id: number
}

// @ts-ignore
const _cloneDeep = require("lodash/cloneDeep")

export default class LetterConnectController {
  public connectionLetters: string[]
  public lettersData: ILettersData[]
  public connectedLetters: IConnectedLetters[] = []
  private circleRadius: number;
  private canvasData: ICanvasData
  private cursorData: ICoordinates | undefined;
  private previousConnection: ILettersData | undefined
  private currentConnection: ILettersData | undefined
  private dispatch: ReturnType<typeof useDispatch> | undefined

  constructor(connectionLetters: string[], canvasSize: number, dispatch: ReturnType<typeof useAppDispatch>) {
    this.connectionLetters = connectionLetters
    this.canvasData = {
      canvasCenterPoint: canvasSize / 2,
      canvasOffsetX: canvasSize / 2,
      canvasOffsetY: canvasSize / 2,
      canvasSize: canvasSize,
      canvasDegreesPerSector: 360 / this.connectionLetters.length
    }
    const offsetForShortLetterPool = this.connectionLetters.length <= 4 ? (5 - this.connectionLetters.length) * 2 : 0
    this.circleRadius = canvasSize / (2 + this.connectionLetters.length) - offsetForShortLetterPool
    this.lettersData = this.connectionLetters.map((letter, index) => {
      const centerPoint = this.canvasData.canvasCenterPoint
      const radius = this.circleRadius
      const degreesPerSector = this.canvasData.canvasDegreesPerSector
      const radians = (index * (degreesPerSector) * Math.PI) / 180 - (1 / 2 * Math.PI);
      const circlePadding = 10
      const centerY = Math.sin(radians) * (centerPoint - radius - circlePadding)
      const centerX = Math.cos(radians) * (centerPoint - radius - circlePadding)
      return {
        letter: letter,
        centerX,
        centerY,
        id: index,
        isConnected: false,
        connectedTo: undefined
      }
    })
    this.dispatch = dispatch
    const deepCopy = _cloneDeep(this.lettersData)
    this.dispatch(setCurrentLettersData(deepCopy))
  }

  draw(c: CanvasRenderingContext2D, canvas: HTMLCanvasElement) {
    c.clearRect(0 - this.canvasData.canvasOffsetX, 0 - this.canvasData.canvasOffsetY, canvas.height + this.canvasData.canvasOffsetX, canvas.width + this.canvasData.canvasOffsetY)
    const lettersData = this.lettersData
    const radius = this.circleRadius
    const fontSize = radius * 1.5
    const ratio = Math.ceil(window.devicePixelRatio);
    c.scale(ratio, ratio)
    c.imageSmoothingEnabled = true;
    c.imageSmoothingQuality = "high"
    c.shadowColor = "rgba(0, 0, 0, 0.1)"
    c.shadowBlur = 4
    c.shadowOffsetX = 2
    c.shadowOffsetY = 4
    c.font = `${fontSize}px appFont`
    c.textBaseline = "middle"
    c.textAlign = "center"
    c.strokeStyle = "#14C7F6"
    c.lineWidth = 10
    c.lineCap = "round"
    c.translate(this.canvasData.canvasOffsetX, this.canvasData.canvasOffsetY)
    lettersData.forEach((letterData) => {
      c.fillStyle = "#14C7F6"
      const x = letterData.centerX
      const y = letterData.centerY
      if (letterData.connectedTo) {
        // Draw connection if it exists
        c.beginPath()
        c.moveTo(x, y)
        c.lineTo(letterData.connectedTo.centerX, letterData.connectedTo.centerY)
        c.stroke()
        c.closePath()
        c.beginPath()
        c.fillStyle =
          // letterData.isConnected ? "#ff2d2d" :
          "#14C7F6"
        // Fix for line below the circle
        c.arc(letterData.connectedTo.centerX, letterData.connectedTo.centerY, radius, 0, 2 * Math.PI, false);
        c.fill()
        c.closePath()
        // c.fillStyle = "white"
        // c.fillText(letterData.connectedTo.letter, letterData.connectedTo.centerX, letterData.connectedTo.centerY + 2)
      }
      if (this.cursorData && letterData.id === this.connectedLetters[this.connectedLetters.length - 1]?.id && this.connectedLetters.length !== this.lettersData.length) {
        // Create line to current cursor position if user start connecting
        c.beginPath()
        c.moveTo(x, y)
        c.lineTo(this.cursorData.x, this.cursorData.y)
        c.stroke()
        c.closePath()
      }
    })
    lettersData.forEach((letterData) => {
      const x = letterData.centerX
      const y = letterData.centerY
      c.fillStyle =
        // letterData.isConnected ? "#ff2d2d" :
        "#14C7F6"
      c.beginPath()
      c.arc(x, y, radius, 0, 2 * Math.PI, false);
      c.fill()
      c.closePath()
      c.fillStyle = "white"
      c.fillText(letterData.letter, x, y)
    })
    c.translate(-this.canvasData.canvasOffsetX, -this.canvasData.canvasOffsetY)
  }

  isConnect(cursorX: number, cursorY: number) {
    const preparedX = cursorX - this.canvasData.canvasOffsetX
    const preparedY = cursorY - this.canvasData.canvasOffsetY
    this.cursorData = {x: preparedX, y: preparedY}
    let triggeredCircle: ILettersData | undefined = undefined;
    const isFirstConnection = this.connectedLetters.length === 0
    const connectionArea = isFirstConnection ? this.circleRadius : this.circleRadius * 0.80
    for (let i = 0; i < this.lettersData.length; i++) {
      const currentData = this.lettersData[i];
      const distanceBetweenCursorAndCircle = getDistance(currentData.centerX, currentData.centerY, preparedX, preparedY)
      if (distanceBetweenCursorAndCircle < connectionArea) {
        triggeredCircle = currentData;
      }
    }
    if (triggeredCircle && !triggeredCircle.isConnected) {
      this.currentConnection = triggeredCircle
      if ((triggeredCircle && this.previousConnection) && triggeredCircle.id === this.previousConnection.id) {
      }
      this.addConnection()
      VKWebApp.useTaptic("medium");
    }
  }

  addConnection() {
    const currentConnection = this.currentConnection
    if (currentConnection) {
      this.connectedLetters.push({letter: currentConnection.letter, id: currentConnection.id})
      if (this.previousConnection) {
        this.previousConnection.connectedTo = currentConnection
      }
      currentConnection.isConnected = true;
      this.previousConnection = this.currentConnection
    }
    if (!this.dispatch) return;
    const connectedLettersDeepCopy = _cloneDeep(this.connectedLetters)
    this.dispatch(setCurrentConnectedLetters(connectedLettersDeepCopy))
  }

  cancelConnections() {
    this.connectedLetters = [];
    this.lettersData = this.lettersData.map(data => ({...data, isConnected: false, connectedTo: undefined}))
    this.previousConnection = undefined
    if (!this.dispatch) return;
    this.dispatch(setCurrentConnectedLetters([]))
  }
}