import {createSlice, current, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {ICrosswordHash, TCrosswordHashMap} from "../../models";
import {getFirstLettersFromCrossword} from "../../utility/getFirstLettersFromCrossword";
import {getWordsFromCrosswordWithMetaData} from "../../utility/getWordsFromCrosswordWithMetaData";
import {IConnectedLetters} from "../../components/entities/LetterConnectCircle/LetterConnectController";
import crosswordApi from "../APIs/crosswordApi";

export interface CrosswordInitialState {
  currentCrosswordOrigin: string[][] | undefined
  currentCrosswordPrepared: IPreparedCrosswordElement[][] | undefined
  currentCrosswordHashMap: TCrosswordHashMap | undefined
  currentConnectionLetters: string[],
  currentConnectedLetters: IConnectedLetters[],
  guessedWords: string[],
  lastGuess: string
  hammerIsActivated: boolean
  lampIsActivated: boolean
  gameIsOver: boolean
}

export interface IPreparedCrosswordElement {
  letter: string,
  isFirst?: boolean
  isHide: boolean
}

export interface ILastGuess {
  guess: string,
  isCorrect: boolean
}

function getRandomInt(max: number, min: number) {
  return Math.floor(Math.random() * (max - min) + min)
}

const initialState: CrosswordInitialState = {
  currentCrosswordHashMap: undefined,
  currentCrosswordPrepared: undefined,
  currentCrosswordOrigin: undefined,
  currentConnectionLetters: [],
  currentConnectedLetters: [],
  guessedWords: [],
  lastGuess: "",
  hammerIsActivated: false,
  lampIsActivated: false,
  gameIsOver: false
}

export function isCrosswordHash(crosswordHash: ICrosswordHash | number): crosswordHash is ICrosswordHash {
  if (Number.isInteger(crosswordHash)) {
    return false;
  } else {
    return true
  }
}

function openLetterByIndex(state: any, data: { row: number, col: number }) {
  const currentCrosswordHashMap = state.currentCrosswordHashMap
  const expectedWords = Object.values(
    _deepClone(current(currentCrosswordHashMap.hashMap)) as Record<string, ICrosswordHash>
  )
    .filter(word => word.wordMeta
      .find((wordMeta, index) => {
        if (wordMeta.x === data.col && wordMeta.y === data.row) {
          state.currentCrosswordHashMap!.hashMap[word.word].wordMeta[index].isOpened = true
          wordMeta.isOpened = true
        }
        return wordMeta.x === data.col && wordMeta.y === data.row
      })
    )
  expectedWords.forEach(word => {
    const openedLetters = word.wordMeta.reduce((acc, cur) => {
      return acc + Number(cur.isOpened)
    }, 0)
    if (openedLetters === word.wordMeta.length && !word.isOpened) {
      state.currentCrosswordHashMap.hashMap[word.word].isOpened = true
      state.guessedWords.push(word.word)
      state.currentCrosswordHashMap!.completed++;
    }
  })
}

// @ts-ignore
const _deepClone = require("lodash/cloneDeep")

const crosswordSlice = createSlice({
  name: "crossword",
  initialState,
  reducers: {
    updateLastGuess: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        lastGuess: action.payload
      }
    },
    setCurrentConnectedLetters: (state, action: PayloadAction<IConnectedLetters[]>) => {
      return {
        ...state,
        currentConnectedLetters: action.payload
      }
    },
    setGameIsOver: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        gameIsOver: action.payload
      }
    },
    openWord: (state, action: PayloadAction<ICrosswordHash>) => {
      const crosswordPrepared = state.currentCrosswordPrepared
      const crosswordHashMap = state.currentCrosswordHashMap
      if (crosswordPrepared && crosswordHashMap) {
        action.payload.wordMeta.forEach(metaData => {
          const row = metaData.y
          const col = metaData.x
          if (crosswordPrepared[row][col]) {
            crosswordPrepared[row][col].isHide = false
            const expectedWords = Object.values(
              _deepClone(current(crosswordHashMap.hashMap)) as Record<string, ICrosswordHash>
            ).filter(word => word.wordMeta
              .find((wordMeta, index) => {
                if (wordMeta.x === col && wordMeta.y === row) {
                  state.currentCrosswordHashMap!.hashMap[word.word].wordMeta[index].isOpened = true
                  wordMeta.isOpened = true
                }
                return wordMeta.x === col && wordMeta.y === row
              })
            ).filter(expectedWordData => expectedWordData.word !== action.payload.word)
            expectedWords
              .forEach(word => {
                const openedLetters = word.wordMeta.reduce((acc, cur) => {
                  return acc + Number(cur.isOpened)
                }, 0)
                if (openedLetters === word.wordMeta.length && !word.isOpened) {
                  word.isOpened = true
                  state.guessedWords.push(word.word)
                  state.currentCrosswordHashMap!.completed++;
                }
              })
          }
        })
        state.guessedWords.push(action.payload.word)
        const currentWordHash = crosswordHashMap.hashMap[action.payload.word]
        if (!currentWordHash.isOpened) {
          crosswordHashMap.completed += 1;
        }
        currentWordHash.isOpened = true
      }
    },
    updateConnectionLetters: (state, action: PayloadAction<string[][]>) => {
      const connectionLetter = action.payload[0]
      return {
        ...state,
        currentConnectionLetters: [...connectionLetter]
      }
    },
    openLetter: (state) => {
      const currentCrosswordPrepared = state.currentCrosswordPrepared
      const currentHashMap = state.currentCrosswordHashMap
      const completedWords = currentHashMap!.completed
      const guessedWords = Object.keys(currentHashMap!.hashMap).length
      if (completedWords === guessedWords) return;
      const currentGuessedWords = state.guessedWords
      if (!currentCrosswordPrepared || !currentHashMap) return;
      if (currentGuessedWords.length === Object.values(currentHashMap.hashMap).length) return;
      const rowsCount = currentCrosswordPrepared.length
      const colsCount = currentCrosswordPrepared[0].reduce((prev) => prev + 1, 0)
      let randomRow: number = getRandomInt(rowsCount, 0);
      let randomCol: number = getRandomInt(colsCount, 0);
      if (currentCrosswordPrepared[randomRow][randomCol].isHide === false || !currentCrosswordPrepared[randomRow][randomCol].letter) {
        while (currentCrosswordPrepared[randomRow][randomCol].isHide !== true || !currentCrosswordPrepared[randomRow][randomCol].letter) {
          randomRow = getRandomInt(rowsCount, 0);
          randomCol = getRandomInt(colsCount, 0);
        }
      }
      openLetterByIndex(state, {row: randomRow, col: randomCol})
      currentCrosswordPrepared[randomRow][randomCol].isHide = false;
    },
    updateCrossword: (state, action: PayloadAction<string[][]>) => {
      const currentCrosswordOrigin = action.payload
      const currentCrosswordPrepared: IPreparedCrosswordElement[][] = action.payload.map(row =>
        row.map(element =>
          ({letter: element, isHide: true})
        )
      )
      const firstLetters = getFirstLettersFromCrossword(currentCrosswordOrigin)
      const currentCrosswordHashMap: TCrosswordHashMap = getWordsFromCrosswordWithMetaData(currentCrosswordOrigin, firstLetters)
      return {
        ...state,
        currentCrosswordPrepared,
        currentCrosswordOrigin,
        currentCrosswordHashMap
      }
    },
    activateHammer: (state) => {
      return {
        ...state,
        hammerIsActivated: true
      }
    },
    openHammer: (state, action: PayloadAction<number>) => { // letter index in flat crossword
      const currentCrosswordPrepared = state.currentCrosswordPrepared
      const currentCrosswordHashMap = state.currentCrosswordHashMap
      const index = action.payload;
      if (!currentCrosswordPrepared || !currentCrosswordHashMap) return;
      const col = index % currentCrosswordPrepared[0].length
      const row = Math.floor(index / currentCrosswordPrepared[0].length)
      currentCrosswordPrepared[row][col].isHide = false
      openLetterByIndex(state, {row, col})
    },
    setHammerActivationStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        hammerIsActivated: action.payload
      }
    },
    setLampActivationStatus: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        lampIsActivated: action.payload
      }
    },
  },
  extraReducers: builder => {
    builder.addCase("RESET_STORE", (state, action) => {
      return {
        ...initialState
      }
    }),
      builder.addMatcher(openWord.match, (state, action) => {
        const openedWord = action.payload
        return {
          ...state,
        }
      })
    builder.addMatcher(crosswordApi.endpoints.init.matchFulfilled, (state, action) => {
      return {
        ...state,
      }
    })
  }
})

export const selectAllWords = (store: RootState) => store.crossword.currentCrosswordOrigin
export const selectPreparedCrossword = (store: RootState) => store.crossword.currentCrosswordPrepared
export const selectCrosswordHashmap = (store: RootState) => store.crossword.currentCrosswordHashMap
export const selectLastGuess = (store: RootState) => store.crossword.lastGuess
export const selectCurrentGameIsOver = (store: RootState) => store.crossword.gameIsOver
export const selectCurrentConnectionLetters = (store: RootState) => store.crossword.currentConnectionLetters
export const selectCurrentCompletedWords = (store: RootState) => store.crossword.currentCrosswordHashMap?.completed
export const selectCurrentWords = (store: RootState) => store.crossword.currentCrosswordHashMap?.hashMap
export const selectCurrentConnectedLetters = (store: RootState) => store.crossword.currentConnectedLetters
export const selectCurrentHammerIsActivated = (store: RootState) => store.crossword.hammerIsActivated


export const {
  updateLastGuess,
  updateConnectionLetters,
  openWord,
  updateCrossword,
  openLetter,
  setCurrentConnectedLetters,
  setHammerActivationStatus,
  setLampActivationStatus,
  openHammer,
  activateHammer,
  setGameIsOver
} = crosswordSlice.actions;


export default crosswordSlice;