import ExternalPlatformControllerInstance, {IOpenInterstitialAdResult} from "./platforms/abstract";
import VKPlatformControllerInstance from "./platforms/vk";
import {baseQuery} from "./platforms/baseQuery";
import {TUserData} from "../../hooks/useInitExternalPlatform";
import {useAppDispatch} from "../../store/store";
import {Dispatch} from "redux";
import PlaydeckPlatformControllerInstance from "./platforms/playdeck";

export type TPlatform = "vk" | "playdeck"

// @ts-ignore
const platform = process.env.REACT_APP_PLATFORM as TPlatform;

export class ExternalPlatformController {
  initiailized: boolean = false;
  platformInstance: ExternalPlatformControllerInstance

  static getBaseQuery() {
    return baseQuery[platform]
  }

  constructor(platform: TPlatform) {
    switch (platform) {
      case "playdeck":
        this.platformInstance = new PlaydeckPlatformControllerInstance()
        break;
      case "vk":
        this.platformInstance = new VKPlatformControllerInstance();
        break
    }
  }

  async init(
    {
      onSuccess,
      dispatch
    }: {
      onSuccess: (userData: TUserData) => void
      dispatch: Dispatch
    }
  ) {
    this.platformInstance.init(dispatch)
      .then(result => {
        onSuccess(result)
      })
      .catch((error) => {
        console.log(error)
      });
  }

  updateLoadingProgress(percentage : number) {
    this.platformInstance.updateLoadingProgress(percentage)
  }

  async checkAdAvailable(type : "reward" | "interstitial"): Promise<boolean> {
    return this.platformInstance.checkAdAvailable(type);
  }

  async showAd(type : "reward" | "interstitial"): Promise<IOpenInterstitialAdResult> {
    const res = await this.platformInstance.showAd(type)
    console.log(res)
    return res;
  }
}