import {useAppDispatch, useAppSelector} from "../store/store";
import {useEffect} from "react";
import {selectCurrentPopoutName, setCurrentPopout} from "../store/Slices/popoutsSlice";

export function useHandleAndroidBackButton() {
  const dispatch = useAppDispatch();
  const activePopout = useAppSelector(selectCurrentPopoutName)
  useEffect(() => {
    const handleGoBackButton = () => {
      if (
        activePopout === "rating"
        || activePopout === "advertising"
      ) {
        dispatch(setCurrentPopout({
          name : "hidden"
        }))
      }
    }
    window.addEventListener('popstate', handleGoBackButton);
    return () => {
      window.removeEventListener('popstate', handleGoBackButton)
    }
  }, [activePopout]);
}