import React, {useEffect} from "react";

export interface IUseOutsideClickConfig {
  skip?: boolean
  doubleClickPrevent?: boolean
}

export default function useOutsideClick(ref: React.MutableRefObject<any>, callback: Function, config ?: IUseOutsideClickConfig) {
  useEffect(() => {
    if (config?.skip) return;

    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        // This callback will play on click outside from ref that you passed to this hook
        event.preventDefault()
        callback(event)
      }
    }

    let timeout: number;
    if (config?.doubleClickPrevent) {
      timeout = setTimeout(() => {
        document.addEventListener("mousedown", handleClickOutside);
      }, 750)
    } else {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (timeout) {
        clearTimeout(timeout)
      }
    };
  }, [ref, config]);
}
