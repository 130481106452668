import SVGs from "../../../assets/svg";
import PNGs from "../../../assets/images";
import {ReactNode} from "react";

export interface IProgressBarProps {
  currentLevel ?: number
  image : ReactNode
}

export default function ProgressBar({currentLevel = 5, image}: IProgressBarProps) {
  const progress = currentLevel % 8
  const progressCalculated = progress === 0 ? 8 : progress
  const progressPercentage = (progressCalculated / 8) * 100
  let progressHeight;
  let progressTextSize;

  if (innerHeight < 650) {
    progressHeight = "h-8"
    progressTextSize = "text-[16px]"
  } else if (innerHeight < 500) {
    progressHeight = "h-7"
    progressTextSize = "text-[14px]"
  } else {
    progressHeight = "h-10"
    progressTextSize = "text-[18px]"
  }
  return (
      <div
          className={`rounded-2xl ${progressHeight} ${progressTextSize} w-screen box-border p-1.5 bg-[#A436D9] relative`}
          style={{
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 1px 1px #CF6BFF",
            maxWidth : "min(calc(648px * 0.7), 70%)",
          }}
      >
        <span className={`absolute text-stroke-chocolate top-1/2 font-extrabold text-white left-1/2 -translate-x-1/2 -translate-y-1/2`}>
          {progressCalculated}/8
        </span>
        <div
            className={`bg-[#6726CC] w-full h-full rounded-2xl p-0.5 box-border`}
            style={{boxShadow: "inset 0px 0px 8px 3px rgba(0, 0, 0, 0.25)"}}
        >
          <div
              className={`bg-[#FFC700] w-full h-full rounded-2xl`}
              style={{
                boxShadow: "inset 0px 0px 1px 1px #D27E00, inset 0px -2px 3px 2px #DE8500, inset 0px 0px 2px 4px #FFE589",
                width : `${progressPercentage}%`
              }}
          >
          </div>
        </div>
        {image}
      </div>
  )
}