import React from 'react';
import {useAppSelector} from "../../../store/store";
import {
  selectCurrentConfig,
  selectCurrentLocalLevel,
  selectCurrentServerLevel,
} from "../../../store/Slices/userSlice";

import {useRewards} from "../../../hooks/useRewards";
import LevelChangerRewardController from "./attachments/LevelChangerRewardController";
import LevelChangerHeader from "./attachments/LevelChangerHeader";
import LevelChangerRewardWrapper from "./attachments/LevelChangerRewardWrapper";

export interface ILevelChangerPopoutProps {

}

export type TReward = {
  diamonds: {
    count: number
    adv: number,
  }
  hammer: {
    count: number
    adv: number
  },
  lamp: {
    count: number
    adv: number,
  }
}

export default function LevelChangerPopout({}: ILevelChangerPopoutProps) {
  const currentConfig = useAppSelector(selectCurrentConfig)
  const serverLevel = useAppSelector(selectCurrentServerLevel)
  const localLevel = useAppSelector(selectCurrentLocalLevel)
  const {reward, adsReward} = useRewards({currentConfig, localLevel})
  let globalPadding;
  let controlsBottom;

  if (innerHeight < 450) {
    globalPadding = "p-6"
    controlsBottom = "bottom-[4%]"
  } else if (innerHeight < 600) {
    globalPadding = "p-8"
    controlsBottom = "bottom-[5%]"
  } else if (innerHeight < 800) {
    globalPadding = "p-10"
    controlsBottom = "bottom-[5%]"
  } else {
    globalPadding = "p-14"
    controlsBottom = "bottom-[10%]"
  }
  return (
    <div
      className={`h-full flex flex-col box-border w-full bg-black/80 items-center ${globalPadding}`}
    >
        <LevelChangerHeader localLevel={localLevel}/>
        <LevelChangerRewardWrapper
          localLevel={localLevel}
          reward={reward}
        />
        <LevelChangerRewardController adsReward={adsReward}
                                      serverLevel={serverLevel}
                                      className={`${controlsBottom}`}
        />
    </div>
  );
}