import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store";

const initialState = {
  currentRoute : {
    view : "default",
    panel : "game"
  },
  history : [{view : "default", panel : "game"}]
}

export interface ISetRoutePayload {
  view : string
  panel : string
}

const routerSlice = createSlice({
  name : "router",
  initialState,
  reducers : {
    setRoute : (state, action : PayloadAction<ISetRoutePayload>) => {
      return {
        ...state,
        currentRoute : {
          view: action.payload.view,
          panel: action.payload.panel
        },
        history : [...state.history, action.payload]
      }
    }
  }
})

export const {setRoute} = routerSlice.actions

export const selectCurrentHistory = (store : RootState) => store.router.history

export default routerSlice;