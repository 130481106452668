import {ClaimRewardPayload} from "../store/Slices/userSlice";

export interface IUseRewardsProps {
  localLevel : number | undefined,
  currentConfig : any
}

export function useRewards({localLevel, currentConfig} : IUseRewardsProps) {
  const reward = {
    diamonds: {
      count: Number(localLevel) % 8 === 0 ?
        Number(currentConfig?.level_8_diamonds_reward)
        : Number(currentConfig?.level_diamonds_reward),
      adv: Number(localLevel) % 8 === 0 ?
        Number(currentConfig?.level_8_diamonds_reward_adv)
        : Number(currentConfig?.level_diamonds_reward_adv)
    },
    hammer: {
      count: Number(localLevel) % 8 === 0 ?
        Number(currentConfig?.level_8_hammer_reward)
        : 0,
      adv: 0
    },
    lamp: {
      count: 0,
      adv: Number(localLevel) % 8 === 0 ?
        Number(currentConfig?.level_8_hammer_reward)
        : 0,
    }
  }
  const defaultReward = Object.entries(reward)
    .reduce((cur, [nextKey, nextValue]) => {
      const result: Partial<ClaimRewardPayload> = {...cur}
      // @ts-ignore
      result[nextKey] = nextValue.count
      return result
    }, {}) as ClaimRewardPayload
  const adsReward = Object.entries(reward)
    .reduce((cur, [nextKey, nextValue]) => {
      const result: Partial<ClaimRewardPayload> = {...cur}
      // @ts-ignore
      result[nextKey] = nextValue.adv
      return result
    }, {}) as ClaimRewardPayload

  return {reward, adsReward, defaultReward}
}