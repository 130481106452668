import React, {useRef} from 'react';
import {Panel, PanelProps} from "@vkontakte/vkui";
import PNGs from "../../../assets/images";
import LevelButton from "../../entities/LevelButton/LevelButton";
import {useAppSelector} from "../../../store/store";
import {selectCurrentLocalLevel} from "../../../store/Slices/userSlice";
import {selectCurrentAvailableHeight} from "../../../store/Slices/adaptiveSlice";

function Levels({id}: PanelProps) {
    const currentLevel = Number(useAppSelector(selectCurrentLocalLevel)) - 1;
    if (!currentLevel) return null;
    const currentPart = Math.floor(currentLevel / 4) + 1
    const currentPartSection = currentLevel % 4;

    const currentHeight = useAppSelector(selectCurrentAvailableHeight)
    // const levelsRefs = new Array(10).fill(0).map(
    //     () => useRef<HTMLImageElement>(null)
    // )
    const renderedLevels = new Array(10).fill(0).map((_, index) => (
        (<div key={`levelButton${index}`} className={"relative"}>
            <LevelButton part={currentPart + index}
                         currentPart={currentPart}
                // ref={levelsRefs[index]}
                         currentPartSection={currentPartSection}
            />
            <svg className={"absolute top-[20px]"}>
                {((currentPart + index) % 2) === 0 ?
                    <path d={"M 85 10 C 140 20 40 140 215 140"} strokeDasharray={14} stroke={"#D9AE7D"}
                          fill={"transparent"} strokeWidth={4}/>
                    : <path d={"M 215 10 C 200 200 120 -20 80 120"} strokeDasharray={14} stroke={"#D9AE7D"}
                            fill={"transparent"} strokeWidth={4}/>
                }
            </svg>
        </div>)
    ))

    return (
        <Panel id={id} style={{
          maxHeight : `${currentHeight}px`
        }}>
            <div style={{
              height : `${currentHeight}px`,
              backgroundImage: `url(${PNGs.LevelsBackground})`
            }} className={`bg-cover bg-center fixed w-screen max-w-[648px] mx-auto`} />
            <div className={`h-full w-full flex flex-col grow relative w-screen max-w-[648px] mx-auto`} style={{
            }}>
                <img src={PNGs.ScrollTop} className={`z-20 w-[380px] block mx-auto`} alt=""/>
                <div
                  style={{height : `calc(${currentHeight}px - 81px)`}}
                    className={`overscroll-none mx-auto z-10 w-[322px] translate-x-1 pb-4 -mt-[58px] overflow-x-hidden overflow-y-scroll box-border`}>
                    <div className={`z-10 pt-[120px] rounded-b-2xl overflow-hidden pb-[24px] w-[322px] h-fit box-border`}
                         style={{
                             backgroundImage: `url(${PNGs.ScrollBody})`,
                             backgroundRepeat: "repeat-y"
                         }}>
                        {renderedLevels}
                    </div>
                </div>
            </div>
        </Panel>
    );
}

export default Levels;