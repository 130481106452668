import React, {useCallback, useEffect, useRef, useState} from 'react';
import CanvasComponent from "../../UI/CanvasComponent/CanvasComponent";
import {LetterConnectController} from "./index";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {updateLastGuess} from "../../../store/Slices/crosswordSlice";
import {IConnectedLetters} from "./LetterConnectController";
import {setCanvasElementData} from "../../../store/Slices/connectionHelperSlice";
import {selectCurrentAvailableHeight} from "../../../store/Slices/adaptiveSlice";

export interface ILetterConnectCircleProps {
  connectedLetters: IConnectedLetters[]
  letterConnector: LetterConnectController
  canvasSize: number
}

export interface ICoordinates {
  x: number,
  y: number
}

function getCanvasTouchCoordinates(e: React.TouchEvent): ICoordinates {
  const target = e.target as HTMLCanvasElement;
  const {x, y} = target.getBoundingClientRect();
  const touch = e.touches[0];
  // Ищем координаты нашего первого свайпа относительно X элемента
  const canvasX = (touch.clientX) - x;
  const canvasY = (touch.clientY) - (y + window.scrollY);

  // Update the canvas with the new coordinates
  // Return the coordinates
  return {x: canvasX, y: canvasY};
}

function getCanvasDragCoordinates(e: React.MouseEvent): ICoordinates {
  const target = e.target as HTMLCanvasElement;
  const {x, y} = target.getBoundingClientRect();
  const canvasX = e.clientX - x
  const canvasY = e.clientY - y

  return {x: canvasX, y: canvasY}
}

function LetterConnectCircle({letterConnector, canvasSize, connectedLetters}: ILetterConnectCircleProps) {
  const dispatch = useAppDispatch();
  const [forceRender, setForceRender] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const onTouchStart = (e: React.TouchEvent) => {
    const canvasCoordinates = getCanvasTouchCoordinates(e)
    if (letterConnector)
      letterConnector.isConnect(canvasCoordinates.x, canvasCoordinates.y)
  }

  const onTouchMove = (e: React.TouchEvent) => {
    const canvasCoordinates = getCanvasTouchCoordinates(e)
    if (letterConnector)
      letterConnector.isConnect(canvasCoordinates.x, canvasCoordinates.y)
  }

  const onTouchEnd = (e: React.TouchEvent) => {
    if (letterConnector)
      letterConnector.cancelConnections()
    if (connectedLetters.length) {
      const lastGuess = connectedLetters.map(letterData => letterData.letter).join("")
      dispatch(updateLastGuess(lastGuess))
    }
  }

  const onDragStart = (e: React.MouseEvent) => {
    setIsMouseDown(true)
    const canvasCoordinates = getCanvasDragCoordinates(e)
    if (letterConnector)
      letterConnector.isConnect(canvasCoordinates.x, canvasCoordinates.y)
  }

  const onDragMove = (e: React.MouseEvent) => {
    if (!isMouseDown) return;
    const canvasCoordinates = getCanvasDragCoordinates(e)
    if (letterConnector)
      letterConnector.isConnect(canvasCoordinates.x, canvasCoordinates.y)
  }

  const onDragEnd = (e: React.MouseEvent) => {
    setIsMouseDown(false)
    e.preventDefault()
    if (letterConnector)
      letterConnector.cancelConnections()
    if (connectedLetters.length) {
      const lastGuess = connectedLetters.map(letterData => letterData.letter).join("")
      dispatch(updateLastGuess(lastGuess))
    }
  }

  const prepareAnimationRef = useRef(
    (context : CanvasRenderingContext2D, canvas : HTMLCanvasElement) => {
      const animation = () => {
        const ratio = Math.ceil(window.devicePixelRatio);
        canvas.width = canvasSize * ratio;
        canvas.height = canvasSize * ratio;
        canvas.style.width = `${canvasSize}px`;
        canvas.style.height = `${canvasSize}px`;
        letterConnector.draw(context, canvas)
        requestAnimationFrame(animation)
      }

      return animation;
    }
  )

  const drawRef = useRef((context : CanvasRenderingContext2D, canvas : HTMLCanvasElement) => {
    if (letterConnector) letterConnector.draw(context, canvas)
  })


  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!letterConnector) return;
    drawRef.current = (context : CanvasRenderingContext2D, canvas : HTMLCanvasElement) => {
      if (letterConnector) letterConnector.draw(context, canvas)
    }
    prepareAnimationRef.current = (context : CanvasRenderingContext2D, canvas : HTMLCanvasElement) => {
      const animation = () => {
        const ratio = Math.ceil(window.devicePixelRatio);
        canvas.width = canvasSize * ratio;
        canvas.height = canvasSize * ratio;
        canvas.style.width = `${canvasSize}px`;
        canvas.style.height = `${canvasSize}px`;
        letterConnector.draw(context, canvas)
        requestAnimationFrame(animation)
      }
      return animation;
    }
    setForceRender(prevState => prevState + 1)
  }, [letterConnector, letterConnector.connectionLetters.join(""), canvasSize]);

  const currentHeight = useAppSelector(selectCurrentAvailableHeight)

  useEffect(() => {
    if (canvasRef.current) {
      const resizeFn: ResizeObserverCallback = (entries, observer) => {
        const canvasElement = entries[0]
        const {x, y, width, height} = canvasElement.target.getBoundingClientRect();
        dispatch(setCanvasElementData({
          width,
          height,
          x,
          y,
          size : canvasSize
        }))
      }
      const resizeObserver = new ResizeObserver(resizeFn)
      resizeObserver.observe(canvasRef.current)
    }
  }, [canvasRef.current, currentHeight, forceRender]);

  return (
    <CanvasComponent
      className={`bg-black/30 rounded-full`}
      draw={drawRef.current}
      outsideRef={canvasRef}
      prepareAnimation={prepareAnimationRef.current}
      draggable={false}
      width={canvasSize}
      height={canvasSize}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      onMouseDown={onDragStart}
      onMouseMove={onDragMove}
      onMouseUp={onDragEnd}
    />
  );
}

export default LetterConnectCircle;