import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IReward} from "../../models";
import {RootState} from "../store";

export interface LevelReward {
  lastLevelReward : IReward | undefined
}

const initialState: LevelReward = {
  lastLevelReward : undefined
}

const rewardSlice = createSlice({
  name : "reward",
  initialState,
  reducers : {
    updateLastLevelReward : (state, action : PayloadAction<IReward>) => ({
      ...state,
      lastLevelReward : action.payload
    }),
  },
  extraReducers : builder => {
    builder.addCase("RESET_STORE", (state, action) => {
      return {
        ...initialState
      }
    })
  }
})

export const { updateLastLevelReward } = rewardSlice.actions
export const selectCurrentLastReward = (state : RootState) => state.reward.lastLevelReward

export default rewardSlice;