import {IFirstLetterMeta, ICrosswordHash, IWordMeta, TCrosswordHashMap} from "../models";

export function getWordsFromCrosswordWithMetaData(originCrossword : string[][], firstLetters : IFirstLetterMeta[]): TCrosswordHashMap {
  const resultArray: TCrosswordHashMap = {completed : 0, hashMap : {}}

  firstLetters.forEach(firstLetterMeta => {
    let currentLetter = firstLetterMeta.letter;
    let currentX = firstLetterMeta.x
    let currentY = firstLetterMeta.y;
    let finalWord = ""
    const wordMeta: IWordMeta[] = []
    while (currentLetter) {
      finalWord += currentLetter;
      wordMeta.push({
        letter: currentLetter,
        x: currentX,
        y: currentY,
        isOpened : false
      })
      if (firstLetterMeta.direction === "vertical") {
        currentY++
      } else {
        currentX++
      }
      currentLetter = originCrossword[currentY]?.[currentX];
    }
    resultArray.hashMap[finalWord] = {
      word: finalWord,
      wordMeta,
      isOpened : false,
    }
  })

  return resultArray;
}