import React, {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../../store/store";
import {selectCrosswordHashmap} from "../../../store/Slices/crosswordSlice";
import {IConnectedLetters} from "../LetterConnectCircle/LetterConnectController";
import {usePrevious} from "@uidotdev/usehooks"
import "./ConnectedLetters.css"

export interface IConnectedLettersProps {
  connectedLetters: IConnectedLetters[]
}

const ConnectedLetters = ({connectedLetters}: IConnectedLettersProps) => {
  const [localConnectedLetters, setLocalConnectedLetters] = useState<IConnectedLetters[]>([]);
  const [isCorrect, setIsCorrect] = useState(false);

  const previousConnectedLetters: IConnectedLetters[] | null = usePrevious(connectedLetters)
  const timeoutRef = useRef<number | undefined>(undefined)

  const crosswordHashMap = useAppSelector(selectCrosswordHashmap)

  const isTyping = connectedLetters.length && previousConnectedLetters;
  const isExit = !connectedLetters.length && previousConnectedLetters?.length

  useEffect(() => {
    if (!crosswordHashMap) return;
    if (crosswordHashMap.hashMap[localConnectedLetters.map(data => data.letter).join("")]) {
      setIsCorrect(() => true)
    } else {
      setIsCorrect(() => false)
    }
  }, [localConnectedLetters.length]);

  useEffect(() => {
    if (isExit) {
      // Оттягиваем удаление из дом дерева, чтобы сработала анимация исчезновения
      timeoutRef.current = setTimeout(() => {
        setLocalConnectedLetters([])
      }, 200)
    } else {
      // Обновляем локальный набор букв
      setLocalConnectedLetters(connectedLetters)
    }

    // Отменяем стирание локального набора букв, если игрок начал писать новое слово
    if (isTyping && timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [connectedLetters, previousConnectedLetters]);


  let shadow = "";
  let bgColor = "";
  let textColor = "";


  if (isExit) {
    shadow = isCorrect ? "correct-shadow" : "incorrect-shadow"
    bgColor = isCorrect ? "bg-[#99F425]" : "bg-[#FF5656]"
    textColor = isCorrect ? "text-[#1B6100]" : "text-[#941C14]"
  } else {
    shadow = "default-shadow"
    bgColor = "bg-[#FEF9E6]"
    textColor = "text-[#A1453F]"
  }

  return (
    <div className="absolute -top-10 flex space-x-1">
      {localConnectedLetters.map((letter, index) => {
        const isNewLetter = index + 1 > previousConnectedLetters?.length
        return (
          <div
            key={`${letter.letter}_${letter.id}_${index}`}
            className={`${textColor} ${isNewLetter && "new-letter"} ${isExit && "letter-disappearance"} ${bgColor} ${shadow} text-[28px] perspective-10 font-bold w-10 h-10 rounded-xl flex justify-center items-center`}
          >
            {letter.letter}
          </div>
        )
      })}
    </div>
  )
}

export default ConnectedLetters;