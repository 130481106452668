import {useEffect, useState} from "react";
import VKConnect, {__DEV} from "../utility/VKConnect";
import {UserInfo} from "@vkontakte/vk-bridge";
import {useAppDispatch} from "../store/store";
import {setLaunchParams, setNotificationAllowed} from "../store/Slices/userSlice";
import {externalPlatformController} from "../index";
import {TPlatform} from "../utility/ExternalPlatformController/ExternalPlatformController";

export interface TUseInitAppVKProps {
  onComplete: (user: TUserData) => any,
}

type TUseInitAppVK = ({onComplete}: TUseInitAppVKProps) => { success: boolean };
export type TUserData = {
  platform: TPlatform,
  user_id : string
  name : string
  photo ?: string
  token ?: string
}

export const useInitExternalPlatform: TUseInitAppVK = function ({onComplete}) {
  const [success, setSuccess] = useState<boolean>(false);
  const dispatch = useAppDispatch()

  const onSuccess = (user_data : TUserData) => {
    onComplete(user_data);
    setSuccess(() => true)
  }

  useEffect(() => {
    externalPlatformController.init({
      onSuccess,
      dispatch
    })
  }, []); // Init vk.ts Data

  return {success}
}