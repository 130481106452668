import {
  IPreparedCrosswordElement,
  openHammer,
  selectCurrentHammerIsActivated, setHammerActivationStatus
} from "../../../store/Slices/crosswordSlice";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {
  completeTipTrainingProgress,
  selectCurrentTipTrainingProgress,
  setIsBlurred
} from "../../../store/Slices/connectionHelperSlice";
import * as amplitude from "@amplitude/analytics-browser";
import {motion} from "framer-motion";
import {PreloadImages} from "../../../assets/images";
import "./CrosswordLetterWrapper.css"

interface CrosswordLetterWrapperProps {
  crosswordElement: IPreparedCrosswordElement,
  children: React.ReactElement
  className?: string
  index: number
  isFirst: boolean
}

function CrosswordLetterWrapper(
  {
    crosswordElement, children, className, index, isFirst
  }: CrosswordLetterWrapperProps
) {
  const dispatch = useAppDispatch()
  const hammerIsActivated = useAppSelector(selectCurrentHammerIsActivated)
  const tipTrainingProgress = useAppSelector(selectCurrentTipTrainingProgress)

  const availableForHammer = crosswordElement.isHide && crosswordElement.letter

  const onClick = () => {
    if (hammerIsActivated && availableForHammer) {
      dispatch(openHammer(index))
      dispatch(setHammerActivationStatus(false))
      dispatch(setIsBlurred(false))
      if (
        tipTrainingProgress?.hammer.isUsed ||
        (typeof tipTrainingProgress?.hammer.isUsed === "undefined")
      ) return;
      dispatch(completeTipTrainingProgress({type: "hammer"}))
      const query = {
        tutorial_step : 5
      }
      amplitude.track("Tutorial Step", query);
    }
  }

  const bgColor = crosswordElement.isHide ? "bg-[#FEF9E6]" : "bg-[#90E02A]";
  const rotateY = crosswordElement.isHide ? "hidden-rotate" : "visible-rotate";
  const boxShadow = crosswordElement.isHide ? "hidden-shadow" : "visible-shadow";
  const letterVisible = crosswordElement.letter ? 'opacity-100' : 'opacity-0'

  return (
    <div
      className={`ease-in-out duration-[400ms] w-full h-full transition ${bgColor} ${rotateY} ${boxShadow} ${letterVisible} rounded-[20%] flex justify-center items-center relative box-border p-2 ${className}`}
      onClick={onClick}
    >
      {
        (isFirst && (
            hammerIsActivated &&
            availableForHammer &&
            !tipTrainingProgress?.hammer.isUsed &&
            (typeof tipTrainingProgress?.hammer.isUsed !== "undefined"))
        ) &&
          <motion.div
              className={`absolute -bottom-10 -right-6`}
              animate={{
                scale: [1, 1.1, 1],
                transition: {
                  duration: 1,
                  repeat: Infinity,
                  ease: "easeInOut"
                }
              }}
          >
              <img src={PreloadImages.Finger} className={`w-[90px]`} alt=""/>
          </motion.div>
      }
      {children}
    </div>
  )
}

export default CrosswordLetterWrapper