import React, {useEffect, useState} from 'react';
import {selectCrosswordHashmap, selectCurrentCompletedWords} from "../../../store/Slices/crosswordSlice";
import {useAppSelector} from "../../../store/store";
import {
  selectCurrentConnectionCanvasElementData,
  selectCurrentLettersData
} from "../../../store/Slices/connectionHelperSlice";
import {motion, useAnimate} from 'framer-motion';
import FingerClick from "../../UI/Icons/FingerClickIcon";
import {ICrosswordHash} from "../../../models";
import { PreloadImages } from "../../../assets/images";
import {selectCurrentAvailableHeight} from "../../../store/Slices/adaptiveSlice";

export interface IConnectionHelperProps {
  currentLevel: number | undefined
}

function ConnectionHelper({currentLevel}: IConnectionHelperProps) {
  const [isActivated, setIsActivated] = useState<boolean>(false);
  const [ref, animate] = useAnimate<HTMLDivElement>()
  const currentLettersData = useAppSelector(selectCurrentLettersData)
  const crosswordHashMap = useAppSelector(selectCrosswordHashmap)
  const currentWords = Object.values(Object(crosswordHashMap?.hashMap)) as ICrosswordHash[] | undefined
  const completedWordCount = currentWords?.reduce((cur, next) => cur + Number(next.isOpened), 0)
  const currentWordToCompletion = currentWords?.find(word => !word.isOpened);
  const currentCanvasConnectionElementData = useAppSelector(selectCurrentConnectionCanvasElementData)

  useEffect(() => {
    let timeoutId: undefined | ReturnType<typeof setTimeout> = undefined;
    if (currentLevel === 1) {
      setIsActivated(true)
    } else if (currentLevel === 2) {
      timeoutId = setTimeout(() => {
        setIsActivated(true)
      }, 6000)
    }

    if (completedWordCount === currentWords?.length) {
    }
    return () => {
      clearTimeout(timeoutId)
      setIsActivated(false)
    }
  }, [currentLevel, completedWordCount, currentWords?.length]);


  useEffect(() => {
    if (
      isActivated &&
      currentWordToCompletion &&
      currentLettersData &&
      currentCanvasConnectionElementData
    ) {
      const connectionLetterElement = currentCanvasConnectionElementData
      const desktopOffset = innerWidth > 648 ? (innerWidth - 648) / 2 : 0
      const fingerOffset = 40
      const centerOffset = fingerOffset / 2
      const x: number[] = []
      const y: number[] = []
      currentWordToCompletion.wordMeta.forEach(meta => {
        const expectedLetterCoordinates = currentLettersData.find(letterData => letterData.letter === meta.letter)
        if (!expectedLetterCoordinates) return;
        x.push((expectedLetterCoordinates.centerX - centerOffset) - desktopOffset + connectionLetterElement.x + connectionLetterElement.size / 2)
        y.push((expectedLetterCoordinates.centerY - centerOffset) + connectionLetterElement.y + connectionLetterElement.size / 2)
      })
      animate(ref.current, {
        opacity: 1
      })
      animate(ref.current, {
        x: x,
        y: y
      }, {repeat: Infinity, duration: x.length * 0.8, ease : "easeOut"})

      return () => {
        if (ref.current) {
          animate(ref.current, {
            opacity: 0
          })
        }
      }
    }
  }, [isActivated, currentCanvasConnectionElementData, currentLettersData, currentWordToCompletion]);


  return (
    <>
      {isActivated && <motion.div className={`fixed z-20 pointer-events-none`}
                   initial={{
                     opacity: 0
                   }}
                   ref={ref}
      >
        <img src={PreloadImages.Finger} className={`w-[90px]`} alt=""/>
      </motion.div>}
    </>
  );
}

export default ConnectionHelper;