import React from "react";
import {IPreparedCrosswordElement} from "../../../store/Slices/crosswordSlice";

interface ICrosswordHiddenLetterProps {
  crosswordElement: IPreparedCrosswordElement,
  gridElementSize: number
}

function CrosswordHiddenLetter({crosswordElement, gridElementSize}: ICrosswordHiddenLetterProps) {
  const color = crosswordElement.isHide ? "text-[#A1453F]" : "text-[#1B6100]"
  const opacity = crosswordElement.isHide ? "opacity-0" : "opacity-100"
  const fontSize = gridElementSize ? gridElementSize - 12 : 12
  return (
    <span
      style={{ fontSize: `${fontSize}px` }}
      className={`transition-opacity duration-500 mb-[6px] opacity-0 ${color} ${opacity} font-extrabold`}
    >
    {crosswordElement.letter}
  </span>)
}

export default CrosswordHiddenLetter