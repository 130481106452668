import React, {SVGProps} from 'react';

export interface IFingerClickProps {
  size : number
}

function FingerClick({size, ...rest} : IFingerClickProps & SVGProps<SVGSVGElement>) {
  return (
    <svg {...rest} width={size} height={28 / 18 * size} viewBox="0 0 18 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.5625 16.6406V12.5391C7.5625 11.633 8.29706 10.8984 9.20312 10.8984C10.1092 10.8984 10.8438 11.633 10.8438 12.5391V16.6406" stroke="white" strokeMiterlimit="10"/>
      <path d="M10.8438 14.1797C10.8438 13.2736 11.5783 12.5391 12.4844 12.5391C13.3904 12.5391 14.125 13.2736 14.125 14.1797V15.8203" stroke="white" strokeMiterlimit="10"/>
      <path d="M14.125 16.6406V15.8203C14.125 14.9143 14.8596 14.1797 15.7656 14.1797C16.6717 14.1797 17.4062 14.9143 17.4062 15.8203V19.1016C17.4062 23.632 13.7336 27.3594 9.20312 27.3594C4.6727 27.3594 1 23.632 1 19.1016V14.1797C1 13.2736 1.73456 12.5391 2.64062 12.5391C3.54669 12.5391 4.28125 13.2736 4.28125 14.1797V19.9219" stroke="white" strokeMiterlimit="10"/>
      <path d="M7.5625 12.5391V5.92188C7.5625 5.01581 6.82794 4.28125 5.92188 4.28125C5.01581 4.28125 4.28125 5.01581 4.28125 5.92188V15.8203" stroke="white" strokeMiterlimit="10"/>
      <path d="M1 5.92188C1 3.20358 3.20358 1 5.92188 1C8.64017 1 10.8438 3.20358 10.8438 5.92188" stroke="white" strokeMiterlimit="10"/>
    </svg>
  );
}

export default FingerClick;