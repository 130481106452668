import { motion } from 'framer-motion';
import React from 'react';
import "./Button.css"
import WatchVideo from "../WatchVideo";
export interface IButtonProps {
  type : "ads" | "primary"
  onClick ?: () => any
  children ?: React.ReactElement | React.ReactElement[] | any
  iconSize ?: number
  iconSpace ?: number
}

function Button({type, onClick, children, iconSize, iconSpace, ...props} : IButtonProps & React.HTMLProps<HTMLButtonElement>) {
  const primaryClasses = `button-shadow-blue text-stroke-blue bg-[#14D5FF]`
  const adsClasses = "button-shadow-orange text-stroke-orange bg-[#FFC700]"
  return (
    <motion.button
        initial={{scale : 1}}
        whileTap={{scale : 0.95}}
        onClick={onClick}
        className={`relative ${type === "primary" ? `${primaryClasses}` : `${adsClasses} leading-none`} rounded-3xl flex items-center text-white font-bold border-4 text-stroke-blue border-white border-solid border ${props.className}`}
    >
      {type === "ads" &&
          <WatchVideo size={iconSize} style={{marginRight : `${iconSpace}px`}}/>
      }
      <span className={``}>{children}</span>
    </motion.button>
  );
}

export default Button;