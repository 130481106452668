import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TPopouts} from "../../models";
import {RootState} from "../store";

export interface IPopoutsState {
  currentPopoutName : TPopouts,
  currentWarningPopout : {
    heading : string,
    article : string
  } | undefined,
  popoutProps : any,
  gameIsHidden : boolean
}

const initialState: IPopoutsState = {
  currentPopoutName : "hidden",
  currentWarningPopout : undefined,
  popoutProps : undefined,
  gameIsHidden : true // Управляется внутри UtilityPopoutWrapper
}

export type TErrorPopoutProps = {
  reset : () => void
  requestName : "levelChange" | "useAdvertising" | "useTip"
}

export type TSetCurrentPopoutPayload = {
  name : TPopouts,
  props ?: any
}

const popoutsSlice = createSlice({
  name : "popouts",
  initialState,
  reducers : {
    setCurrentPopout : (state, action : PayloadAction<TSetCurrentPopoutPayload>) => {
      return {
        ...state,
        currentPopoutName : action.payload.name,
        popoutProps : action.payload.props ? action.payload.props : undefined
      }
    },
    setCurrentWarningPopout : (state, action : PayloadAction<IPopoutsState["currentWarningPopout"]>) => ({
      ...state,
      currentWarningPopout : action.payload,
    }),
    setGameIsHidden : (state, action : PayloadAction<boolean>) => {
      return {
        ...state,
        gameIsHidden : action.payload
      }
    },
  },
  extraReducers : builder => {
    builder.addCase("RESET_STORE", (state, action) => {
      return {
        ...initialState
      }
    })
  }
})

export const selectCurrentPopoutProps = (store : RootState) => store.popouts.popoutProps
export const selectCurrentWarningPopout = (store : RootState) => store.popouts.currentWarningPopout

export const selectCurrentPopoutName = (store : RootState) => store.popouts.currentPopoutName
export const selectCurrentGameIsHidden = (store : RootState) => store.popouts.gameIsHidden
export const {
  setCurrentWarningPopout,
  setCurrentPopout,
  setGameIsHidden
} = popoutsSlice.actions
export default popoutsSlice;