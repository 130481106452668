import React, {useEffect, useRef, useState} from 'react';
import {
  IPreparedCrosswordElement,
  selectCurrentHammerIsActivated,
} from "../../../store/Slices/crosswordSlice";
import { useAppSelector} from "../../../store/store";
import { CrosswordLetterWrapper } from '../../entities';
import {CrosswordHiddenLetter, SquaredElementsGrid} from "../../UI";
import {selectCurrentAvailableHeight, selectCurrentBannerHeight} from "../../../store/Slices/adaptiveSlice";

export default function Crossword({preparedCrossword, ...props}: ICrosswordProps & React.HTMLProps<HTMLDivElement>) {
  const ref = useRef<HTMLDivElement>(null);
  const hammerIsActivated = useAppSelector(selectCurrentHammerIsActivated)
  const [gridElementSize, setGridElementSize] = useState<number>(0);
  const [crosswordIsDisplayable, setCrosswordIsDisplayable] = useState(true);
  if (!preparedCrossword?.length) return null;

  let isFirstFounded = false;
  const flatCrossword = preparedCrossword.flat(1)
  const renderedCrossword = flatCrossword.map((crosswordElement, index) => {
      let isFirst = false;
      if (!isFirstFounded && crosswordElement.letter) {
        isFirst = true
        isFirstFounded = true
      }
      return (
        <CrosswordLetterWrapper key={`${index}_${crosswordElement.letter}`}
                                crosswordElement={crosswordElement}
                                index={index}
                                isFirst={isFirst}
                                className={isFirst && hammerIsActivated ? "z-[1]" : "z-[0]"}
        >
          <CrosswordHiddenLetter key={`${index}_${crosswordElement.letter}_letter`}
                                 crosswordElement={crosswordElement}
                                 gridElementSize={gridElementSize}/>
        </CrosswordLetterWrapper>
      )
    }
  )

  const availableHeight = useAppSelector(selectCurrentAvailableHeight)
  const bannerHeight = useAppSelector(selectCurrentBannerHeight) ?? 0
  useEffect(() => {
    setCrosswordIsDisplayable(false);
    setTimeout(() => {
      setCrosswordIsDisplayable(true);
    }, 100)
  }, [availableHeight, bannerHeight]);

  return (
    <div className={`${props.className}`} ref={ref}>
      <SquaredElementsGrid
        gap={4}
        rows={preparedCrossword.length}
        cols={preparedCrossword[0].length}
        setGridElementSize={setGridElementSize}
        className={`pt-4 pb-8 px-3`}
      >
        {crosswordIsDisplayable && renderedCrossword}
      </SquaredElementsGrid>
    </div>
  );
}

export interface ICrosswordProps {
  preparedCrossword: IPreparedCrosswordElement[][] | undefined
}