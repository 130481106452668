import {useEffect} from "react";
import {PlatformType} from "@vkontakte/vkui/dist/lib/platform";
import {BannerAdLayoutType, BannerAdLocation} from "@vkontakte/vk-bridge";
import VKConnect from "../utility/VKConnect";
import {setBannerHeight} from "../store/Slices/adaptiveSlice";
import {useAppDispatch} from "../store/store";
import {usePlatform} from "@vkontakte/vkui";

type TUseVKAdsPayload = {
  VKInitSuccess : boolean
}

export default function useVKAds({VKInitSuccess} : TUseVKAdsPayload) {
  // @ts-ignore
  const externalPlatform = process.env.REACT_APP_PLATFORM;


  const dispatch = useAppDispatch()
  const vkPlatform = usePlatform()
  if (externalPlatform !== "vk") return;

  const bannerLocation: Record<PlatformType, BannerAdLayoutType> = {
    "android": BannerAdLayoutType.RESIZE,
    "vkcom": BannerAdLayoutType.RESIZE,
    "ios": BannerAdLayoutType.OVERLAY,
  }

  const selectedBannerLocation = bannerLocation[vkPlatform]

  useEffect(() => {
    if (!VKInitSuccess) return;
    VKConnect.send('VKWebAppShowBannerAd', {
      banner_location: BannerAdLocation.BOTTOM,
      layout_type: selectedBannerLocation,
      can_close: true
    })
      .then((data) => {
        if (data.result) {
          if (vkPlatform === "ios") {
            dispatch(setBannerHeight(data.banner_height))
          }
        }
      })
      .catch((error1) => {
        VKConnect.send('VKWebAppShowBannerAd', {
          banner_location: BannerAdLocation.BOTTOM,
          layout_type: selectedBannerLocation,
          can_close: true
        })
          .then((data) => {
            if (data.result) {
              if (vkPlatform === "ios") {
                dispatch(setBannerHeight(data.banner_height))
              }
            }
          })
          .catch((error2) => {
            VKConnect.send('VKWebAppShowBannerAd', {
              banner_location: BannerAdLocation.BOTTOM,
              layout_type: selectedBannerLocation,
              can_close: true
            })
              .then((data) => {
                if (data.result) {
                  if (vkPlatform === "ios") {
                    dispatch(setBannerHeight(data.banner_height))
                  }
                }
              })
              .catch((error3) => {
                console.log("3", error3)
              });
          });
      });
  }, [VKInitSuccess]);
}